/**
 * WebDataRocks Reporting v1.4.15 (https://www.webdatarocks.com/)
 * Copyright © 2024 WebDataRocks. All rights reserved.
 *
 * By downloading, installing, and/or using WebDataRocks, you agree with the terms of
 * WebDataRocks End User License Agreement, a copy of which is located at: https://www.webdatarocks.com/license-agreement/.
 */
/* ===== RESET STYLES ===== */
#wdr-pivot-view,
#wdr-toolbar-wrapper {
  line-height: 1;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -webkit-text-size-adjust: none;
}
#wdr-pivot-view input[type=text],
#wdr-toolbar-wrapper input[type=text] {
  -webkit-appearance: none;
}
#wdr-pivot-view div,
#wdr-pivot-view span,
#wdr-pivot-view applet,
#wdr-pivot-view object,
#wdr-pivot-view iframe,
#wdr-pivot-view h1,
#wdr-pivot-view h2,
#wdr-pivot-view h3,
#wdr-pivot-view h4,
#wdr-pivot-view h5,
#wdr-pivot-view h6,
#wdr-pivot-view p,
#wdr-pivot-view blockquote,
#wdr-pivot-view pre,
#wdr-pivot-view a,
#wdr-pivot-view abbr,
#wdr-pivot-view acronym,
#wdr-pivot-view address,
#wdr-pivot-view big,
#wdr-pivot-view cite,
#wdr-pivot-view code,
#wdr-pivot-view del,
#wdr-pivot-view dfn,
#wdr-pivot-view em,
#wdr-pivot-view img,
#wdr-pivot-view ins,
#wdr-pivot-view kbd,
#wdr-pivot-view q,
#wdr-pivot-view s,
#wdr-pivot-view samp,
#wdr-pivot-view small,
#wdr-pivot-view strike,
#wdr-pivot-view strong,
#wdr-pivot-view sub,
#wdr-pivot-view sup,
#wdr-pivot-view tt,
#wdr-pivot-view var,
#wdr-pivot-view b,
#wdr-pivot-view u,
#wdr-pivot-view i,
#wdr-pivot-view center,
#wdr-pivot-view dl,
#wdr-pivot-view dt,
#wdr-pivot-view dd,
#wdr-pivot-view ol,
#wdr-pivot-view ul,
#wdr-pivot-view li,
#wdr-pivot-view fieldset,
#wdr-pivot-view form,
#wdr-pivot-view label,
#wdr-pivot-view legend,
#wdr-pivot-view table,
#wdr-pivot-view caption,
#wdr-pivot-view tbody,
#wdr-pivot-view tfoot,
#wdr-pivot-view thead,
#wdr-pivot-view tr,
#wdr-pivot-view th,
#wdr-pivot-view td,
#wdr-pivot-view article,
#wdr-pivot-view aside,
#wdr-pivot-view canvas,
#wdr-pivot-view details,
#wdr-pivot-view embed,
#wdr-pivot-view figure,
#wdr-pivot-view figcaption,
#wdr-pivot-view footer,
#wdr-pivot-view header,
#wdr-pivot-view menu,
#wdr-pivot-view nav,
#wdr-pivot-view output,
#wdr-pivot-view ruby,
#wdr-pivot-view section,
#wdr-pivot-view summary,
#wdr-pivot-view time,
#wdr-pivot-view mark,
#wdr-pivot-view audio,
#wdr-pivot-view video,
#wdr-toolbar-wrapper div,
#wdr-toolbar-wrapper span,
#wdr-toolbar-wrapper applet,
#wdr-toolbar-wrapper object,
#wdr-toolbar-wrapper iframe,
#wdr-toolbar-wrapper h1,
#wdr-toolbar-wrapper h2,
#wdr-toolbar-wrapper h3,
#wdr-toolbar-wrapper h4,
#wdr-toolbar-wrapper h5,
#wdr-toolbar-wrapper h6,
#wdr-toolbar-wrapper p,
#wdr-toolbar-wrapper blockquote,
#wdr-toolbar-wrapper pre,
#wdr-toolbar-wrapper a,
#wdr-toolbar-wrapper abbr,
#wdr-toolbar-wrapper acronym,
#wdr-toolbar-wrapper address,
#wdr-toolbar-wrapper big,
#wdr-toolbar-wrapper cite,
#wdr-toolbar-wrapper code,
#wdr-toolbar-wrapper del,
#wdr-toolbar-wrapper dfn,
#wdr-toolbar-wrapper em,
#wdr-toolbar-wrapper img,
#wdr-toolbar-wrapper ins,
#wdr-toolbar-wrapper kbd,
#wdr-toolbar-wrapper q,
#wdr-toolbar-wrapper s,
#wdr-toolbar-wrapper samp,
#wdr-toolbar-wrapper small,
#wdr-toolbar-wrapper strike,
#wdr-toolbar-wrapper strong,
#wdr-toolbar-wrapper sub,
#wdr-toolbar-wrapper sup,
#wdr-toolbar-wrapper tt,
#wdr-toolbar-wrapper var,
#wdr-toolbar-wrapper b,
#wdr-toolbar-wrapper u,
#wdr-toolbar-wrapper i,
#wdr-toolbar-wrapper center,
#wdr-toolbar-wrapper dl,
#wdr-toolbar-wrapper dt,
#wdr-toolbar-wrapper dd,
#wdr-toolbar-wrapper ol,
#wdr-toolbar-wrapper ul,
#wdr-toolbar-wrapper li,
#wdr-toolbar-wrapper fieldset,
#wdr-toolbar-wrapper form,
#wdr-toolbar-wrapper label,
#wdr-toolbar-wrapper legend,
#wdr-toolbar-wrapper table,
#wdr-toolbar-wrapper caption,
#wdr-toolbar-wrapper tbody,
#wdr-toolbar-wrapper tfoot,
#wdr-toolbar-wrapper thead,
#wdr-toolbar-wrapper tr,
#wdr-toolbar-wrapper th,
#wdr-toolbar-wrapper td,
#wdr-toolbar-wrapper article,
#wdr-toolbar-wrapper aside,
#wdr-toolbar-wrapper canvas,
#wdr-toolbar-wrapper details,
#wdr-toolbar-wrapper embed,
#wdr-toolbar-wrapper figure,
#wdr-toolbar-wrapper figcaption,
#wdr-toolbar-wrapper footer,
#wdr-toolbar-wrapper header,
#wdr-toolbar-wrapper menu,
#wdr-toolbar-wrapper nav,
#wdr-toolbar-wrapper output,
#wdr-toolbar-wrapper ruby,
#wdr-toolbar-wrapper section,
#wdr-toolbar-wrapper summary,
#wdr-toolbar-wrapper time,
#wdr-toolbar-wrapper mark,
#wdr-toolbar-wrapper audio,
#wdr-toolbar-wrapper video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: none;
  transition: none;
  border-collapse: collapse;
  border-spacing: 0;
}
#wdr-pivot-view ol,
#wdr-pivot-view ul,
#wdr-toolbar-wrapper ol,
#wdr-toolbar-wrapper ul {
  list-style: none;
}
#wdr-pivot-view table,
#wdr-toolbar-wrapper table {
  border-collapse: collapse;
  border-spacing: 0;
}
#wdr-pivot-view caption,
#wdr-pivot-view th,
#wdr-pivot-view td,
#wdr-toolbar-wrapper caption,
#wdr-toolbar-wrapper th,
#wdr-toolbar-wrapper td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
#wdr-pivot-view q,
#wdr-pivot-view blockquote,
#wdr-toolbar-wrapper q,
#wdr-toolbar-wrapper blockquote {
  quotes: none;
}
#wdr-pivot-view q:before,
#wdr-pivot-view q:after,
#wdr-pivot-view blockquote:before,
#wdr-pivot-view blockquote:after,
#wdr-toolbar-wrapper q:before,
#wdr-toolbar-wrapper q:after,
#wdr-toolbar-wrapper blockquote:before,
#wdr-toolbar-wrapper blockquote:after {
  content: "";
  content: none;
}
#wdr-pivot-view img,
#wdr-toolbar-wrapper img {
  max-width: 100%;
  height: auto;
  height: initial;
  border: none;
}
#wdr-pivot-view a,
#wdr-toolbar-wrapper a {
  text-decoration: none;
}
#wdr-pivot-view article,
#wdr-pivot-view aside,
#wdr-pivot-view details,
#wdr-pivot-view figcaption,
#wdr-pivot-view figure,
#wdr-pivot-view footer,
#wdr-pivot-view header,
#wdr-pivot-view main,
#wdr-pivot-view menu,
#wdr-pivot-view nav,
#wdr-pivot-view section,
#wdr-pivot-view summary,
#wdr-toolbar-wrapper article,
#wdr-toolbar-wrapper aside,
#wdr-toolbar-wrapper details,
#wdr-toolbar-wrapper figcaption,
#wdr-toolbar-wrapper figure,
#wdr-toolbar-wrapper footer,
#wdr-toolbar-wrapper header,
#wdr-toolbar-wrapper main,
#wdr-toolbar-wrapper menu,
#wdr-toolbar-wrapper nav,
#wdr-toolbar-wrapper section,
#wdr-toolbar-wrapper summary {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#wdr-pivot-view :focus,
#wdr-toolbar-wrapper :focus {
  outline: none;
}
#wdr-pivot-view h1,
#wdr-pivot-view h2,
#wdr-pivot-view h3,
#wdr-pivot-view h4,
#wdr-pivot-view h5,
#wdr-pivot-view h6,
#wdr-toolbar-wrapper h1,
#wdr-toolbar-wrapper h2,
#wdr-toolbar-wrapper h3,
#wdr-toolbar-wrapper h4,
#wdr-toolbar-wrapper h5,
#wdr-toolbar-wrapper h6 {
  font-weight: normal;
}
/*-----------STANDARD RESET END-----------------*/
@font-face {
  font-family: "webdatarocks-icons";
  src: url("./theme/assets/webdatarocks-icons.woff") format("woff"), url("./theme/assets/webdatarocks-icons.ttf") format("truetype"), url("./theme/assets/webdatarocks-icons.svg#webdatarocks-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* === Do NOT remove and do NOT change .wdr-csscheck class === */
.wdr-csscheck {
  font-family: 'webdatarocks';
}
/* ===== PIVOT UI ===== */
#wdr-pivot-view .wdr-ui,
#wdr-pivot-view .wdr-ui span,
#wdr-pivot-view .wdr-ui div,
#wdr-pivot-view .wdr-ui p,
#wdr-pivot-view .wdr-ui a,
#wdr-pivot-view .wdr-ui table,
#wdr-pivot-view .wdr-ui table th,
#wdr-pivot-view .wdr-ui table tr,
#wdr-pivot-view .wdr-ui table td,
#wdr-pivot-view .wdr-ui ul,
#wdr-pivot-view .wdr-ui li,
#wdr-pivot-view .wdr-ui input,
#wdr-pivot-view .wdr-ui textarea,
#wdr-pivot-view .wdr-ui select,
#wdr-toolbar-wrapper .wdr-toolbar-ui,
#wdr-toolbar-wrapper .wdr-toolbar-ui span,
#wdr-toolbar-wrapper .wdr-toolbar-ui div,
#wdr-toolbar-wrapper .wdr-toolbar-ui p,
#wdr-toolbar-wrapper .wdr-toolbar-ui a,
#wdr-toolbar-wrapper .wdr-toolbar-ui table,
#wdr-toolbar-wrapper .wdr-toolbar-ui table th,
#wdr-toolbar-wrapper .wdr-toolbar-ui table tr,
#wdr-toolbar-wrapper .wdr-toolbar-ui table td,
#wdr-toolbar-wrapper .wdr-toolbar-ui ul,
#wdr-toolbar-wrapper .wdr-toolbar-ui li,
#wdr-toolbar-wrapper .wdr-toolbar-ui input,
#wdr-toolbar-wrapper .wdr-toolbar-ui textarea,
#wdr-toolbar-wrapper .wdr-toolbar-ui select {
  font-family: Arial, sans-serif;
  font-size: 12px;
  text-align: left;
  color: #111;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  border-radius: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-shadow: none;
  float: none;
  line-height: normal;
}
#wdr-pivot-view .wdr-ui-mobile,
#wdr-pivot-view .wdr-ui-mobile span,
#wdr-pivot-view .wdr-ui-mobile div,
#wdr-pivot-view .wdr-ui-mobile p,
#wdr-pivot-view .wdr-ui-mobile a,
#wdr-pivot-view .wdr-ui-mobile table,
#wdr-pivot-view .wdr-ui-mobile th,
#wdr-pivot-view .wdr-ui-mobile tr,
#wdr-pivot-view .wdr-ui-mobile td,
#wdr-pivot-view .wdr-ui-mobile ul,
#wdr-pivot-view .wdr-ui-mobile li,
#wdr-pivot-view .wdr-ui-mobile input,
#wdr-pivot-view .wdr-ui-mobile textarea,
#wdr-pivot-view .wdr-ui-mobile select,
#wdr-pivot-view .wdr-ui-mobile option {
  font-size: 14px;
  -webkit-border-radius: 0;
  border-radius: 0;
}
#wdr-pivot-view .wdr-ui-mobile input[type="text"] {
  height: 28px;
  line-height: 28px;
  border: 1px solid #999;
  background-color: #ffffff;
}
#wdr-pivot-view .wdr-ui-mobile select {
  background-color: #ffffff;
}
#wdr-pivot-view .wdr-ui a {
  font-weight: normal;
}
#wdr-pivot-view .wdr-ui ul > li:before {
  width: 0px !important;
  height: 0px !important;
  margin: 0;
  padding: 0;
  border: 0;
}
#wdr-pivot-view a.wdr-ui {
  color: inherit;
  font-weight: normal;
}
#wdr-pivot-view a.wdr-ui:hover {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
}
#wdr-pivot-view input.wdr-ui {
  font-size: 12px;
  outline: none;
}
#wdr-pivot-view input.wdr-ui-mobile {
  font-size: 14px;
}
#wdr-pivot-view span.wdr-ui {
  font-size: 11px;
}
#wdr-pivot-view span.wdr-ui-mobile {
  font-size: 14px;
}
#wdr-pivot-view div.wdr-ui-clear {
  clear: both;
}
#wdr-pivot-view input[type=text].wdr-ui-text-input,
#wdr-pivot-view input[type=number].wdr-ui-text-input {
  /*vertical-align: -webkit-baseline-middle;
    vertical-align: -moz-middle-with-baseline;*/
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  height: 38px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 10px;
  padding-left: 10px;
  text-align: left;
  color: #111;
  font-size: 14px;
  background: #fff;
  border: 1px solid #d5d5d5;
}
#wdr-pivot-view input[type=text].wdr-ui-text-input.wdr-ui-prompt {
  color: #cecece;
  font-style: italic;
}
#wdr-pivot-view input[type=text].wdr-ui-text-input.wdr-ui-mobile {
  height: 30px;
}
#wdr-pivot-view .wdr-ui textarea.wdr-ui-text-area {
  resize: none;
  border: 1px solid #999;
  padding: 1px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  color: #111;
  font-size: 14px;
}
#wdr-pivot-view .wdr-ui textarea.wdr-ui-text-area:focus {
  outline: none;
}
#wdr-pivot-view [class^="wdr-ui-icon-"]:before,
#wdr-pivot-view [class*=" wdr-ui-icon-"]:before,
#wdr-pivot-view .wdr-ui-icon:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: webdatarocks-icons !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#wdr-pivot-view .wdr-icon-act_add:before {
  content: "\e900";
}
#wdr-pivot-view .wdr-icon-act_calc:before {
  content: "\e90a";
}
#wdr-pivot-view .wdr-icon-act_check:before {
  content: "\e902";
}
#wdr-pivot-view .wdr-icon-act_close:before {
  content: "\e908";
}
#wdr-pivot-view .wdr-icon-act_close_small:before {
  content: "\e904";
}
#wdr-pivot-view .wdr-icon-act_filter:before {
  content: "\e905";
}
#wdr-pivot-view .wdr-icon-act_font:before {
  content: "\e906";
}
#wdr-pivot-view .wdr-icon-act_search:before {
  content: "\e913";
}
#wdr-pivot-view .wdr-icon-act_table_settings:before {
  content: "\e90c";
}
#wdr-pivot-view .wdr-icon-act_table_sort:before {
  content: "\e90d";
}
#wdr-pivot-view .wdr-icon-act_trash:before {
  content: "\e908";
}
#wdr-pivot-view .wdr-icon-arrow_down:before {
  content: "\e90f";
}
#wdr-pivot-view .wdr-icon-arrow_left:before {
  content: "\e910";
}
#wdr-pivot-view .wdr-icon-arrow_right:before {
  content: "\e911";
}
#wdr-pivot-view .wdr-icon-arrow_up:before {
  content: "\e912";
}
#wdr-pivot-view .wdr-icon-count_down:before {
  content: "\e926";
}
#wdr-pivot-view .wdr-icon-count_up:before {
  content: "\e927";
}
#wdr-pivot-view .wdr-icon-dd_connect_csv:before {
  content: "\e928";
}
#wdr-pivot-view .wdr-icon-dd_connect_json:before {
  content: "\e929";
}
#wdr-pivot-view .wdr-icon-dd_export_bar:before {
  content: "\e92a";
}
#wdr-pivot-view .wdr-icon-dd_export_bar_hor:before {
  content: "\e92b";
}
#wdr-pivot-view .wdr-icon-dd_export_bar_lane:before {
  content: "\e92c";
}
#wdr-pivot-view .wdr-icon-dd_export_bar_stack:before {
  content: "\e92d";
}
#wdr-pivot-view .wdr-icon-dd_export_line:before {
  content: "\e92e";
}
#wdr-pivot-view .wdr-icon-dd_export_pie:before {
  content: "\e92f";
}
#wdr-pivot-view .wdr-icon-dd_export_scatter:before {
  content: "\e930";
}
#wdr-pivot-view .wdr-icon-dd_format:before {
  content: "\e931";
}
#wdr-pivot-view .wdr-icon-dd_format_1:before {
  content: "\e932";
}
#wdr-pivot-view .wdr-icon-dd_open_local:before {
  content: "\e933";
}
#wdr-pivot-view .wdr-icon-dd_open_remote:before {
  content: "\e934";
}
#wdr-pivot-view .wdr-icon-dd_save_exel:before {
  content: "\e935";
}
#wdr-pivot-view .wdr-icon-dd_save_html:before {
  content: "\e936";
}
#wdr-pivot-view .wdr-icon-dd_save_image:before {
  content: "\e937";
}
#wdr-pivot-view .wdr-icon-dd_save_pdf:before {
  content: "\e938";
}
#wdr-pivot-view .wdr-icon-dd_save_print:before {
  content: "\e939";
}
#wdr-pivot-view .wdr-icon-direction_direction_y:before {
  content: "\e93a";
}
#wdr-pivot-view .wdr-icon-menu_connect:before {
  content: "\e93c";
}
#wdr-pivot-view .wdr-icon-menu_export:before {
  content: "\e93d";
}
#wdr-pivot-view .wdr-icon-menu_fields:before {
  content: "\e93e";
}
#wdr-pivot-view .wdr-icon-menu_format:before {
  content: "\e93f";
}
#wdr-pivot-view .wdr-icon-menu_fullscreen_close:before {
  content: "\e940";
}
#wdr-pivot-view .wdr-icon-menu_fullscreen_open:before {
  content: "\e941";
}
#wdr-pivot-view .wdr-icon-menu_grid:before {
  content: "\e942";
}
#wdr-pivot-view .wdr-icon-menu_open:before {
  content: "\e943";
}
#wdr-pivot-view .wdr-icon-menu_options:before {
  content: "\e944";
}
#wdr-pivot-view .wdr-icon-menu_save:before {
  content: "\e945";
}
#wdr-pivot-view .wdr-icon-notif_arrow:before {
  content: "\e949";
}
#wdr-pivot-view .wdr-icon-notif_confirm:before {
  content: "\e901";
}
#wdr-pivot-view .wdr-icon-notif_info:before {
  content: "\e948";
}
#wdr-pivot-view .wdr-icon-notif_warning:before {
  content: "\e949";
}
.wdr-ui-icon {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.wdr-ui-vam {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.wdr-ui-icon-vam {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.wdr-ui-ham {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.wdr-ui-icon-ham {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.wdr-ui-icon-c {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.wdr-shadow {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
#wdr-pivot-view span.wdr-ui-label {
  color: #111;
  font-size: 14px;
}
#wdr-pivot-view span.wdr-ui-label * {
  font-size: 14px;
}
#wdr-pivot-view span.wdr-ui-label b {
  font-weight: bold;
}
#wdr-pivot-view span.wdr-ui-label-light {
  color: #999;
}
#wdr-pivot-view a.wdr-ui-btn,
#wdr-toolbar-wrapper a.wdr-ui-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-transform: uppercase;
  position: relative;
  outline: none;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 12px;
  letter-spacing: 0.5px;
  min-width: 90px;
  color: #555;
  border-radius: 4px;
  background: #DBDBDB;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#wdr-pivot-view a.wdr-ui-btn:hover,
#wdr-toolbar-wrapper a.wdr-ui-btn:hover {
  font-weight: bold;
  color: #626262;
  background: #e3e3e3;
  /*-webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
        box-shadow: 0 2px 6px rgba(0,0,0,0.2);*/
}
#wdr-pivot-view a.wdr-ui-btn:hover,
#wdr-toolbar-wrapper a.wdr-ui-btn:hover,
#wdr-pivot-view a.wdr-ui-btn:focus,
#wdr-toolbar-wrapper a.wdr-ui-btn:focus,
#wdr-pivot-view a.wdr-ui-btn:active,
#wdr-toolbar-wrapper a.wdr-ui-btn:active {
  outline: none;
}
#wdr-pivot-view a.wdr-ui-btn-dark,
#wdr-toolbar-wrapper a.wdr-ui-btn-dark {
  color: #fff;
  background: #555;
}
#wdr-pivot-view a.wdr-ui-btn-dark:hover,
#wdr-toolbar-wrapper a.wdr-ui-btn-dark:hover {
  font-weight: bold;
  color: #fff;
  background: #626262;
}
#wdr-pivot-view a.wdr-ui-btn-light,
#wdr-toolbar-wrapper a.wdr-ui-btn-light {
  background: #fbfbfb;
  border: 1px solid #d5d5d5;
  color: #555;
  font-weight: normal;
  text-transform: none;
  padding: 11px 11px;
}
#wdr-pivot-view a.wdr-ui-btn-light:hover,
#wdr-toolbar-wrapper a.wdr-ui-btn-light:hover {
  background: #e3e3e3;
  /*border-color: @ui-border-color-light;*/
  color: #555;
  font-weight: normal;
}
#wdr-pivot-view a.wdr-ui-btn-superlight,
#wdr-toolbar-wrapper a.wdr-ui-btn-superlight {
  background: #fff;
  /*border: @ui-element-border;*/
  color: #555;
  font-weight: normal;
  text-transform: none;
  padding: 11px 11px;
  padding-top: 9px;
}
#wdr-pivot-view a.wdr-ui-btn-superlight:hover,
#wdr-toolbar-wrapper a.wdr-ui-btn-superlight:hover {
  background: #fbfbfb;
  /*border-color: @ui-border-color-light;*/
  color: #555;
  font-weight: normal;
}
#wdr-pivot-view a.wdr-ui-btn.wdr-ui-btn-close,
#wdr-toolbar-wrapper a.wdr-ui-btn.wdr-ui-btn-close {
  font-size: 0;
  border: none;
  background: none;
  min-width: auto;
  min-width: initial;
  width: 30px;
  height: 30px;
  padding: 2px;
}
#wdr-pivot-view a.wdr-ui-btn.wdr-ui-btn-close:before,
#wdr-toolbar-wrapper a.wdr-ui-btn.wdr-ui-btn-close:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  content: "\e908";
  font-size: 26px;
}
#wdr-pivot-view .wdr-ui-btns-row,
#wdr-toolbar-wrapper .wdr-ui-btns-row {
  font-size: 0;
}
#wdr-pivot-view .wdr-ui-btns-row .wdr-ui-btn,
#wdr-toolbar-wrapper .wdr-ui-btns-row .wdr-ui-btn {
  margin-right: 20px;
  height: 38px;
}
#wdr-pivot-view .wdr-ui-btns-row .wdr-ui-btn:last-child,
#wdr-toolbar-wrapper .wdr-ui-btns-row .wdr-ui-btn:last-child {
  margin-right: 0;
}
#wdr-pivot-view .wdr-icon-notif_arrow:before,
#wdr-toolbar-wrapper .wdr-icon-notif_arrow:before {
  content: "\e949";
}
#wdr-pivot-view a.wdr-ui-toggle-btn {
  color: #999;
  font-size: 14px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid #d5d5d5;
  background: #fff;
}
#wdr-pivot-view a.wdr-ui-toggle-btn.wdr-selected {
  color: #111;
  background: #f1f1f1;
}
#wdr-pivot-view a.wdr-ui-toggle-btn:hover {
  color: #111;
  background: #e3e3e3;
}
#wdr-pivot-view a.wdr-ui-toggle-btn-dark {
  color: #111;
  background: #f1f1f1;
}
#wdr-pivot-view a.wdr-ui-toggle-btn-dark.wdr-selected {
  color: #fff;
  background: #555;
  border-color: #555;
}
#wdr-pivot-view a.wdr-ui-toggle-btn-dark.wdr-selected:hover {
  background: #626262;
  border-color: #626262;
}
#wdr-pivot-view a.wdr-ui-link-btn {
  background: none;
  font-size: 12px;
  text-decoration: underline;
  line-height: normal;
  width: auto;
  width: initial;
  height: auto;
  height: initial;
  border: none;
  padding: 0;
  cursor: pointer;
}
#wdr-pivot-view a.wdr-ui-link-btn:hover {
  background: none;
  text-decoration: none;
}
#wdr-pivot-view a.wdr-ui-link-btn.wdr-ui-pressed {
  background: none;
  text-decoration: underline;
}
#wdr-pivot-view a.wdr-ui-checkbox {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  vertical-align: middle;
}
#wdr-pivot-view a.wdr-ui-checkbox .wdr-ui-label {
  padding-left: 30px;
  position: relative;
  line-height: 1.2;
  color: #111;
  font-size: 14px;
}
#wdr-pivot-view a.wdr-ui-checkbox .wdr-ui-label:before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #d5d5d5;
  background: #fff;
}
#wdr-pivot-view a.wdr-ui-checkbox .wdr-ui-label:before:hover {
  border-color: #999;
}
#wdr-pivot-view a.wdr-ui-checkbox .wdr-ui-label:after {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  opacity: 0;
  content: '\e901';
  color: #fff;
  font-size: 16px;
  left: 1px;
}
#wdr-pivot-view a.wdr-ui-checkbox.wdr-selected .wdr-ui-label:after {
  opacity: 1;
}
#wdr-pivot-view a.wdr-ui-checkbox.wdr-selected .wdr-ui-label:before {
  border: none;
  background: #555;
}
#wdr-pivot-view a.wdr-ui-checkbox.wdr-ui-semi-selected .wdr-ui-label:after {
  content: '';
  background: #555;
  width: 10px;
  height: 10px;
  left: 4px;
  top: 9px;
  opacity: 1;
}
#wdr-pivot-view div.wdr-ui-dropdown {
  text-align: left;
  display: inline-block;
  vertical-align: top;
  width: auto;
  width: initial;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-btn {
  display: block;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #d5d5d5;
  padding: 9px 35px 10px 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  height: 38px;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-btn .wdr-ui-label {
  color: #111;
  font-size: 14px;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-btn:after {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e902";
  right: 8px;
  font-size: 21px;
  color: #999;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-btn.wdr-selected:after {
  content: "\e904";
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-btn.wdr-selected + .wdr-ui-dropdown-list {
  margin-top: 5px;
  visibility: visible;
  opacity: 1;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list {
  display: block;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 100%;
  border: 1px solid #d5d5d5;
  margin-top: 20px;
  visibility: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 1;
  background: #fff;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list ul {
  overflow-y: auto;
  max-height: 300px;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list ul li {
  display: block;
  border-bottom: 1px solid #DBDBDB;
  padding: 11px 9px;
  cursor: pointer;
  position: relative;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list ul li .wdr-ui-label {
  color: #111;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list ul li:last-child {
  border-bottom: none;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list ul li:hover {
  background: #f1f1f1;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list ul li.wdr-selected {
  background: #f1f1f1;
  padding-right: 25px;
  position: relative;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list ul li.wdr-selected:after {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: '\e901';
  color: #999;
  right: 8px;
  font-size: 18px;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list:before,
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list:after {
  content: '';
  position: absolute;
  display: block;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list:before {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 5.5px 6px 5.5px;
  border-color: transparent transparent #d5d5d5 transparent;
  top: -6px;
}
#wdr-pivot-view div.wdr-ui-dropdown .wdr-ui-dropdown-list:after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 4.5px 5px 4.5px;
  border-color: transparent transparent #fff transparent;
  top: -5px;
}
/* popup */
#wdr-pivot-view div.wdr-ui-window {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  background: #fff;
  margin: 0 auto 0;
  border: 1px solid #d5d5d5;
  padding: 24px 30px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  z-index: 7;
}
#wdr-pivot-view div.wdr-ui-window .wdr-popup-header {
  min-height: 40px;
  margin-bottom: 10px;
}
#wdr-pivot-view div.wdr-ui-window .wdr-popup-header.wdr-ph-simple {
  margin-bottom: 24px;
}
#wdr-pivot-view div.wdr-ui-window .wdr-popup-header .wdr-popup-icons-row {
  margin-bottom: 25px;
  margin-top: 7px;
}
#wdr-pivot-view div.wdr-ui-window .wdr-popup-header .wdr-ui-btns-row {
  position: absolute;
  right: 30px;
}
#wdr-pivot-view div.wdr-ui-window .wdr-popup-title {
  color: #111;
  font-size: 24px;
  display: block;
  padding: 5px 0;
}
#wdr-pivot-view div.wdr-ui-window .wdr-popup-subtitle {
  color: #999;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}
#wdr-pivot-view div.wdr-ui-popup {
  z-index: 12;
}
#wdr-pivot-view div.wdr-ui-popup.wdr-layout-mobile {
  padding: 20px;
}
#wdr-pivot-view div.wdr-ui-popup.wdr-layout-mobile .wdr-popup-subtitle {
  display: none;
}
#wdr-pivot-view div.wdr-ui-popup.wdr-layout-mobile .wdr-ui-btns-row {
  right: 20px;
}
#wdr-pivot-view div.wdr-ui-popup.wdr-layout-mobile-small .wdr-popup-header {
  margin-bottom: 10px;
}
#wdr-pivot-view div.wdr-ui-popup.wdr-layout-mobile-small .wdr-popup-header .wdr-ui-col {
  width: 100%;
  margin-bottom: 10px;
}
#wdr-pivot-view div.wdr-ui-popup.wdr-layout-mobile-small .wdr-popup-header .wdr-ui-btns-row {
  position: relative;
  right: initial;
  right: auto;
  text-align: left;
}
#wdr-pivot-view div.wdr-ui-popup.wdr-layout-mobile-small .wdr-popup-header .wdr-ui-btns-row .wdr-ui-btn {
  width: calc(50% - 10px);
  width: -webkit-calc(50% - 10px);
}
#wdr-pivot-view div.wdr-ui-popup.wdr-layout-mobile-small .wdr-popup-header .wdr-popup-title {
  font-size: 18px;
  line-height: 1;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-ui-row,
#wdr-pivot-view .wdr-ui .wdr-ui-row {
  font-size: 0;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-ui-col,
#wdr-pivot-view .wdr-ui .wdr-ui-col,
#wdr-toolbar-wrapper .wdr-ui .wdr-ui-col-2,
#wdr-pivot-view .wdr-ui .wdr-ui-col-2,
#wdr-toolbar-wrapper .wdr-ui .wdr-ui-col-3,
#wdr-pivot-view .wdr-ui .wdr-ui-col-3,
#wdr-toolbar-wrapper .wdr-ui .wdr-ui-col-9,
#wdr-pivot-view .wdr-ui .wdr-ui-col-9 {
  display: inline-block;
  vertical-align: top;
  min-height: 1px;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-ui-col-2,
#wdr-pivot-view .wdr-ui .wdr-ui-col-2 {
  width: 50%;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-ui-col-3,
#wdr-pivot-view .wdr-ui .wdr-ui-col-3 {
  margin-right: 30px;
  width: -webkit-calc(33.33% - 20px);
  width: calc(33.33% - 20px);
}
#wdr-toolbar-wrapper .wdr-ui .wdr-ui-col-3:nth-child(3n+3),
#wdr-pivot-view .wdr-ui .wdr-ui-col-3:nth-child(3n+3) {
  margin-right: 0;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-ui-col-9,
#wdr-pivot-view .wdr-ui .wdr-ui-col-9 {
  margin-right: 1px;
  margin-bottom: 1px;
  width: -webkit-calc(11.11% - 0.9px);
  width: calc(11.11% - 0.9px);
}
#wdr-toolbar-wrapper .wdr-ui .wdr-ui-col-9:nth-child(9n+9),
#wdr-pivot-view .wdr-ui .wdr-ui-col-9:nth-child(9n+9) {
  margin-right: 0;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-vam,
#wdr-pivot-view .wdr-ui .wdr-vam {
  vertical-align: middle;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-vat,
#wdr-pivot-view .wdr-ui .wdr-vat {
  vertical-align: top;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-tar,
#wdr-pivot-view .wdr-ui .wdr-tar {
  text-align: right;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-tal,
#wdr-pivot-view .wdr-ui .wdr-tal {
  text-align: left;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-tac,
#wdr-pivot-view .wdr-ui .wdr-tac {
  text-align: center;
}
#wdr-toolbar-wrapper .wdr-ui .wdr-mb30,
#wdr-pivot-view .wdr-ui .wdr-mb30 {
  margin-bottom: 30px;
}
#wdr-pivot-view .wdr-helper {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: inline-block;
  background: #f1f1f1;
  opacity: 0.9;
  padding: 10px 9px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #d5d5d5;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  color: #111;
}
#wdr-pivot-view .wdr-header-helper {
  text-transform: uppercase;
  border: none;
  background: #DBDBDB;
  padding: 8px 6px;
  font-size: 12px;
  border: 1px solid #999;
  cursor: move;
}
#wdr-pivot-view .wdr-drop-indicator {
  height: 2px;
  background: #555;
  pointer-events: none;
}
#wdr-pivot-view div.wdr-ui-panel {
  position: relative;
  background: #fff;
  border: 1px solid #999;
}
#wdr-pivot-view div.wdr-ui-modal-overlay {
  background-color: white;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
}
#wdr-pivot-view div.wdr-ui-modal-overlay.wdr-ui-opaque {
  opacity: 1;
}
#wdr-pivot-view div.wdr-ui-modal-overlay.wdr-ui-transparent {
  opacity: 0;
}
#wdr-pivot-view div.wdr-ui-toolbar {
  background: #fff;
  border-top: 1px dotted #dcdcdc;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin-bottom: 0px;
}
#wdr-pivot-view div.wdr-ui-hgroup {
  overflow: hidden;
}
#wdr-pivot-view div.wdr-ui-hgroup > * {
  float: left;
}
#wdr-pivot-view div.wdr-ui-vgroup > * {
  display: block;
}
#wdr-pivot-view ul.wdr-ui-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#wdr-pivot-view .wdr-ui-disabled,
#wdr-toolbar-wrapper .wdr-ui-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
#wdr-pivot-view div.wdr-ui-vlist {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
#wdr-pivot-view div.wdr-ui-vlist ul.wdr-ui-list {
  position: absolute;
  overflow: hidden;
  width: 100%;
  top: 0;
  background: #fff;
}
#wdr-pivot-view div.wdr-ui-vlist div.wdr-ui-vlist-placeholder {
  width: 100%;
}
#wdr-pivot-view div.wdr-ui-divider {
  background-color: #f1f1f1;
  position: absolute;
  z-index: 1;
}
#wdr-pivot-view div.wdr-ui-divider:hover {
  background-color: #ececec;
}
#wdr-pivot-view div.wdr-ui-divider:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "\e910";
  font-size: 13px;
  color: #999;
}
#wdr-pivot-view div.wdr-ui-divider.wdr-ui-horizontal {
  left: 0;
  right: 0;
  height: 9px;
}
#wdr-pivot-view div.wdr-ui-divider.wdr-ui-horizontal:hover {
  cursor: row-resize;
}
#wdr-pivot-view div.wdr-ui-divider.wdr-ui-horizontal:before {
  top: -2px;
}
#wdr-pivot-view div.wdr-ui-divider.wdr-ui-vertical {
  top: 0;
  bottom: 0;
  width: 9px;
}
#wdr-pivot-view div.wdr-ui-divider.wdr-ui-vertical:hover {
  cursor: col-resize;
}
#wdr-pivot-view div.wdr-ui-divider.wdr-ui-vertical:before {
  left: -2px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
#wdr-pivot-view .wdr-ui-toolbar-mobile {
  height: 48px;
  border-bottom: 1px solid #999;
}
#wdr-pivot-view .wdr-ui-toolbar-mobile .wdr-ui-header-display {
  pointer-events: none;
  position: absolute;
  top: 12px;
  font-size: 17px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  width: 100%;
}
@media only screen and (max-width: 560px) {
  #wdr-pivot-view .wdr-ui-toolbar-mobile .wdr-ui-header-display {
    font-size: 14px;
    top: 15px;
  }
}
#wdr-pivot-view .wdr-ui-right {
  position: absolute;
  right: 0;
  top: 0;
}
#wdr-pivot-view .wdr-ui-left {
  position: absolute;
  left: 0;
  top: 0;
}
#wdr-pivot-view a.wdr-ui-btn.wdr-disabled {
  pointer-events: none;
  cursor: default;
  color: #aaa;
  opacity: 0.4;
}
#wdr-pivot-view a.wdr-ui-btn:hover.wdr-disabled {
  background: #fff;
}
#wdr-pivot-view a.wdr-ui-btn:active.wdr-disabled {
  background: #fff;
}
#wdr-pivot-view .wdr-credits {
  display: block;
  height: 14px;
  position: absolute;
  right: 0;
  bottom: -18px;
  opacity: 0.5;
  filter: grayscale(1);
  transition: opacity 0.5s;
}
#wdr-pivot-view .wdr-credits .wdr-created {
  font-size: 11px;
  vertical-align: top;
  color: #777;
  opacity: 0;
  display: inline-block;
  margin-right: 2px;
}
#wdr-pivot-view .wdr-credits a {
  font-size: 9px !important;
}
#wdr-pivot-view .wdr-credits:hover {
  opacity: 1;
  filter: none;
}
#wdr-pivot-view .wdr-credits:hover .wdr-created {
  opacity: 1;
}
/* ===== PIVOT UI END ===== */
/* Filter */
#wdr-pivot-view #wdr-filter-view {
  width: 500px;
  min-width: 320px;
  /* layouts */
}
#wdr-pivot-view #wdr-filter-view .wdr-popup-header {
  margin-bottom: 25px;
}
#wdr-pivot-view #wdr-filter-view .wdr-popup-header .wdr-ui-row .wdr-ui-btns-row {
  white-space: nowrap;
  position: absolute;
  right: 0;
}
#wdr-pivot-view #wdr-filter-view .wdr-popup-header .wdr-popup-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#wdr-pivot-view #wdr-filter-view .wdr-bold-btn {
  font-weight: bold;
  text-transform: uppercase;
}
#wdr-pivot-view #wdr-filter-view a.wdr-ui-toggle-btn.wdr-sort-btn {
  width: 70px;
  font-weight: bold;
  text-transform: uppercase;
}
#wdr-pivot-view #wdr-filter-view #wdr-sort-label {
  margin-right: 10px;
}
#wdr-pivot-view #wdr-filter-view .wdr-sort-btns {
  display: inline-block;
  margin-bottom: 30px;
}
#wdr-pivot-view #wdr-filter-view .wdr-sort-btns .wdr-sort-btn:first-child {
  border-right: none;
}
#wdr-pivot-view #wdr-filter-view #wdr-top10-btn {
  position: relative;
  width: 140px;
  margin-bottom: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
#wdr-pivot-view #wdr-filter-view #wdr-top10-btn .wdr-btn-canceltopx {
  display: none;
  position: absolute;
  right: 4px;
  top: 8px;
  width: 12px;
  height: 16px;
}
#wdr-pivot-view #wdr-filter-view #wdr-top10-btn .wdr-btn-canceltopx:hover {
  text-decoration: none;
}
#wdr-pivot-view #wdr-filter-view #wdr-top10-btn .wdr-btn-canceltopx:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  content: "\e908";
  font-size: 15px;
  left: -15px;
}
#wdr-pivot-view #wdr-filter-view #wdr-top10-btn.wdr-not-empty {
  padding-right: 20px;
}
#wdr-pivot-view #wdr-filter-view #wdr-top10-btn.wdr-not-empty .wdr-btn-canceltopx {
  display: block;
}
#wdr-pivot-view #wdr-filter-view #wdr-top10-btn.wdr-narrow {
  width: 90px;
}
#wdr-pivot-view #wdr-filter-view #wdr-top10-btn.wdr-narrow.wdr-not-empty {
  padding-left: 12px;
}
#wdr-pivot-view #wdr-filter-view #wdr-top10-btn.wdr-narrow .wdr-btn-canceltopx {
  font-size: 0;
  right: 6px;
}
#wdr-pivot-view #wdr-filter-view #wdr-top10-btn.wdr-selected .wdr-btn-canceltopx {
  color: #fff;
}
#wdr-pivot-view #wdr-filter-view #wdr-add-group-btn {
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 20px;
  margin-bottom: 30px;
  width: 100px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table {
  border: 1px solid #d5d5d5;
  margin-bottom: 30px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-ui-checkbox.wdr-selected {
  font-weight: bold;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-content {
  position: relative;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-content #wdr-members-filter-list {
  height: 100%;
  overflow-y: auto;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-content #wdr-members-filter-list li {
  border-bottom: 1px solid #DBDBDB;
  padding: 10px 9px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-content #wdr-members-filter-list li .wdr-expand-toggle-btn {
  color: #999;
  display: inline-block;
  font-size: 14px;
  position: relative;
  min-height: 1px;
  width: 30px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-content #wdr-members-filter-list li .wdr-expand-toggle-btn:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  content: "\e911";
  top: -17px;
  text-align: center;
  width: 30px;
  padding: 8px 0;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-content #wdr-members-filter-list li .wdr-expand-toggle-btn.wdr-selected:before {
  content: "\e90f";
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-content #wdr-members-filter-list li .wdr-ungroup-btn {
  position: absolute;
  right: 10px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header {
  border-bottom: 1px solid #d5d5d5;
  background: #f1f1f1;
  padding: 10px 9px;
  position: relative;
  height: 37px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-btn-collapse {
  margin-left: 8px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header #wdr-select-counter {
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
  color: #999;
  font-size: 12px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  border-left: 1px solid #d5d5d5;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap .wdr-search-inp {
  position: absolute;
  right: 0;
  width: 100%;
  height: 36px;
  padding-left: 10px;
  padding-right: 39px;
  font-size: 14px;
  background: #fff;
  opacity: 0;
  border: none;
  visibility: hidden;
  border-left: 1px solid #d5d5d5;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap .wdr-icon-act_close {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 39px;
  cursor: pointer;
  color: #999;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap .wdr-icon-act_close:before {
  font-size: 26px;
  position: absolute;
  top: 5px;
  left: 8px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap .wdr-icon-act_close:hover {
  color: #555;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap .wdr-search-btn {
  position: absolute;
  height: 36px;
  width: 100%;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
  background: transparent;
  color: #999;
  padding-top: 10px;
  padding-right: 16px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap .wdr-search-btn .wdr-icon-act_search {
  color: #999;
  position: absolute;
  top: 5px;
  right: 7px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap .wdr-search-btn .wdr-icon-act_search:before {
  font-size: 25px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap .wdr-search-btn:hover {
  color: #111;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap .wdr-search-btn:hover .wdr-icon-act_search,
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap .wdr-search-btn:hover .wdr-icon-act_search:before {
  color: #111;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap.wdr-search-opened {
  width: 50%;
  height: 100%;
  border-left: none;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap.wdr-search-opened .wdr-icon-act_close,
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap.wdr-search-opened .wdr-search-inp {
  visibility: visible;
  opacity: 1;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap.wdr-search-opened #wdr-select-counter {
  display: none;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap.wdr-search-opened .wdr-search-btn {
  display: none;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table .wdr-filters-table-header .wdr-search-wrap.wdr-search-opened .wdr-search-btn .wdr-icon-act_search:before {
  color: #DBDBDB;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table:last-child {
  margin-bottom: 0;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-table.wdr-ft-contentheight-220 .wdr-filters-table-content {
  height: 222px;
}
#wdr-pivot-view #wdr-filter-view #wdr-filters-topx-view #wdr-top-amount-input {
  width: 70px;
  vertical-align: top;
}
#wdr-pivot-view #wdr-filter-view #wdr-filters-topx-view #wdr-topx-btns {
  margin-left: 10px;
  display: inline-block;
  width: 140px;
}
#wdr-pivot-view #wdr-filter-view #wdr-filters-topx-view #wdr-topx-btns a.wdr-ui-toggle-btn.wdr-topx-btn {
  width: 50%;
}
#wdr-pivot-view #wdr-filter-view #wdr-filters-topx-view #wdr-topx-btns .wdr-topx-btn:first-child {
  border-right: none;
}
#wdr-pivot-view #wdr-filter-view #wdr-filters-topx-view #wdr-top-measures-dropdown {
  width: calc(100% - 230px);
  width: -webkit-calc(100% - 230px);
  min-width: 120px;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
#wdr-pivot-view #wdr-filter-view #wdr-add-group-view #wdr-save-btn {
  width: 80px;
  color: #111;
}
#wdr-pivot-view #wdr-filter-view #wdr-add-group-view #wdr-group-name-input {
  width: -webkit-calc(100% - 90px);
  width: calc(100% - 90px);
  margin-right: 10px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-subview {
  margin-bottom: 20px;
  margin-top: -10px;
  background: #fbfbfb;
  border: 1px solid #DBDBDB;
  position: relative;
  padding: 14px 13px;
  font-size: 0;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-subview:before,
#wdr-pivot-view #wdr-filter-view .wdr-filters-subview:after {
  content: '';
  position: absolute;
  display: block;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-subview:before {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 6.5px 7px 6.5px;
  border-color: transparent transparent #DBDBDB transparent;
  right: 63px;
  top: -7px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-subview:after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 5.5px 6px 5.5px;
  border-color: transparent transparent #fbfbfb transparent;
  right: 64px;
  top: -6px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-subview#wdr-add-group-view:before {
  right: 150px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filters-subview#wdr-add-group-view:after {
  right: 151px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filter-pages-layout .wdr-topx-col {
  text-align: left;
}
#wdr-pivot-view #wdr-filter-view .wdr-filter-pages-layout #wdr-add-group-view:before {
  right: initial;
  left: 45px;
}
#wdr-pivot-view #wdr-filter-view .wdr-filter-pages-layout #wdr-add-group-view:after {
  right: initial;
  left: 46px;
}
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small .wdr-popup-header .wdr-ui-col,
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small .wdr-popup-header .wdr-ui-col-2 {
  width: 100%;
}
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small .wdr-popup-header .wdr-ui-col-2 {
  margin-bottom: 10px;
}
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small .wdr-popup-header .wdr-ui-btns-row {
  position: relative;
}
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small .wdr-popup-header .wdr-popup-title {
  line-height: 1.1;
}
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small a.wdr-ui-toggle-btn.wdr-sort-btn {
  width: 60px;
}
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small #wdr-sort-label {
  display: none;
}
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small #wdr-select-counter {
  display: none !important;
}
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small #wdr-filters-topx-view #wdr-top-amount-input,
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small #wdr-filters-topx-view #wdr-topx-btns {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
}
#wdr-pivot-view #wdr-filter-view.wdr-layout-mobile-small #wdr-filters-topx-view #wdr-top-measures-dropdown {
  width: 100%;
  min-width: auto;
  min-width: initial;
  margin: 0;
  margin-top: 10px;
}
/* Pivot view */
#wdr-pivot-view {
  min-width: 300px;
  min-height: 200px;
  position: relative;
  background: #fff;
  border: 1px solid #d5d5d5;
  outline: none;
  box-sizing: border-box;
}
#wdr-pivot-view #wdr-branding-bar {
  background-color: #fff;
  border-top: 1px solid #d5d5d5;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: 0px;
  z-index: 2;
}
#wdr-pivot-view #wdr-branding-bar > span {
  line-height: 21px;
  color: #999;
}
#wdr-pivot-view #wdr-branding-bar > span a {
  color: #ff9b79;
}
#wdr-pivot-view #wdr-branding-bar #wdr-version-label {
  float: left;
  margin-left: 5px;
}
@media only screen and (max-width: 660px) {
  #wdr-pivot-view #wdr-branding-bar span,
  #wdr-pivot-view #wdr-branding-bar span a {
    font-size: 11px;
  }
}
@media only screen and (max-width: 520px) {
  #wdr-pivot-view #wdr-branding-bar #wdr-version-label {
    display: none;
  }
}
#wdr-pivot-view #wdr-branding-bar #wdr-link {
  float: right;
  margin-right: 5px;
  vertical-align: top;
}
#wdr-pivot-view #wdr-branding-bar #wdr-info-icon {
  display: inline-block;
  height: 13px;
  width: 13px;
  margin-left: 5px;
  margin-right: 2px;
  color: #d5d5d5;
  font-size: 16px;
  cursor: pointer;
}
#wdr-pivot-view #wdr-branding-bar #wdr-info-icon:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  content: "\ea0c";
}
@media all and (max-width: 600px) {
  #wdr-pivot-view {
    min-width: initial;
    min-height: initial;
  }
}
#wdr-pivot-view #wdr-grid-view {
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#wdr-pivot-view .wdr-grid-column {
  width: 100px;
}
#wdr-pivot-view .wdr-grid-row {
  height: 30px;
}
#wdr-pivot-view .wdr-grid-column-mobile {
  width: 100px;
}
#wdr-pivot-view .wdr-grid-row-mobile {
  height: 30px;
}
#wdr-pivot-view span.wdr-ui-label.wdr-pivot-title {
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding: 4px 10px 5px;
}
/* Fields */
#wdr-pivot-view .wdr-fields-view-wrap {
  position: absolute;
  z-index: 11;
  right: 0;
  top: 0;
}
#wdr-pivot-view .wdr-fields-view-wrap.wdr-fields-opened {
  position: relative;
  z-index: 12;
}
#wdr-pivot-view .wdr-fields-view-wrap.wdr-fields-opened #wdr-btn-open-fields {
  display: none;
}
#wdr-pivot-view .wdr-fields-view-wrap #wdr-btn-open-fields {
  font-size: 0;
  border: none;
  border-radius: 0;
  /*border-bottom-left-radius: 4px;*/
  background-color: #fff;
  min-width: auto;
  min-width: initial;
  padding: 9px;
  width: 44px;
  height: 44px;
  border-left: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
#wdr-pivot-view .wdr-fields-view-wrap #wdr-btn-open-fields:hover {
  background-color: #f1f1f1;
}
#wdr-pivot-view .wdr-fields-view-wrap #wdr-btn-open-fields:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  padding-left: 1px;
  content: "\e907";
  font-size: 24px;
  color: #555;
}
#wdr-pivot-view #wdr-fields-view {
  /* layouts */
}
#wdr-pivot-view #wdr-fields-view.wdr-pivot-fields {
  width: 780px;
  min-width: 450px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-header .wdr-ui-col:first-child {
  max-width: 300px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-header .wdr-ui-col:first-child span.wdr-ui-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-header #wdr-btn-add-measure {
  padding-right: 40px;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-header #wdr-btn-add-measure:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 4px;
  color: #999;
  font-size: 28px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-header #wdr-btn-add-measure:hover:before {
  color: #555;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-ui-row {
  height: 330px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-ui-col-3 {
  height: 100%;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap {
  border: 1px solid #d5d5d5;
  position: relative;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header {
  background: #f1f1f1;
  border-bottom: 1px solid #d5d5d5;
  padding: 5px 10px;
  position: relative;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header span.wdr-ui-label {
  color: #999;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header.wdr-list-header-wide {
  padding: 0px;
  height: 35px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-list-header-label-wrap {
  width: calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
  padding: 10px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header #wdr-btn-collapse-expand-all {
  margin-left: 8px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  border-left: 1px solid #d5d5d5;
  z-index: 9;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap .wdr-search-inp {
  z-index: 2;
  position: absolute;
  right: 0;
  width: 100%;
  height: 34px;
  padding-left: 39px;
  padding-right: 39px;
  color: #111;
  font-size: 14px;
  background: #fff;
  opacity: 0;
  border: none;
  visibility: hidden;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap .wdr-icon-act_close {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 39px;
  cursor: pointer;
  z-index: 3;
  color: #999;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap .wdr-icon-act_close:before {
  font-size: 26px;
  position: absolute;
  top: 5px;
  left: 8px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap .wdr-icon-act_close:hover {
  color: #555;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap .wdr-search-btn {
  position: absolute;
  height: 36px;
  width: 100%;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  z-index: 3;
  border: none;
  background: transparent;
  color: #999;
  padding-bottom: 4px;
  padding-right: 16px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap .wdr-search-btn .wdr-icon-act_search {
  color: #999;
  position: absolute;
  top: 5px;
  right: 7px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap .wdr-search-btn .wdr-icon-act_search:before {
  font-size: 25px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap .wdr-search-btn:hover {
  color: #111;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap .wdr-search-btn:hover .wdr-icon-act_search,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap .wdr-search-btn:hover .wdr-icon-act_search:before {
  color: #111;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap.wdr-search-opened {
  width: 100%;
  height: 100%;
  border-left: none;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap.wdr-search-opened .wdr-icon-act_close,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap.wdr-search-opened .wdr-search-inp {
  visibility: visible;
  opacity: 1;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap.wdr-search-opened #wdr-select-counter {
  display: none;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap.wdr-search-opened .wdr-search-btn {
  font-size: 0;
  width: 40px;
  left: 0;
  pointer-events: none;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-search-wrap.wdr-search-opened .wdr-search-btn .wdr-icon-act_search:before {
  color: #DBDBDB;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content {
  min-height: 37px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content .wdr-ui-list {
  height: calc(100% - 2px);
  height: -webkit-calc(100% - 2px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content li {
  border-bottom: 1px solid #DBDBDB;
  padding: 10px 9px;
  position: relative;
  cursor: move;
  background: #fff;
  height: 38px;
  white-space: nowrap;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content li span {
  vertical-align: middle;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content li #wdr-text-display {
  font-size: 14px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content li #wdr-drag-handle {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: #d5d5d5;
  font-size: 20px;
  padding: 8px 6px;
  right: 0;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content li #wdr-drag-handle:before {
  content: "\e910";
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content li.wdr-values {
  padding-left: 26px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content li.wdr-values:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  content: "\e914";
  font-size: 24px;
  color: #d5d5d5;
  left: 2px;
  top: 6px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content li.wdr-selected #wdr-text-display {
  font-weight: bold;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li #wdr-text-display {
  position: absolute;
  left: 30px;
  right: 30px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-check-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-unchecked:not(.wdr-level-folder) #wdr-icon-display {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  border: 1px solid #d5d5d5;
  background: #fff;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-checked:not(.wdr-level-folder) #wdr-icon-display {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  background: #555;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-checked:not(.wdr-level-folder) #wdr-icon-display:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  content: '\e901';
  color: #fff;
  font-size: 16px;
  left: 1px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-folder {
  cursor: pointer;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-folder #wdr-text-display {
  right: 10px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-folder #wdr-icon-display {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 5px;
  position: relative;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-folder #wdr-icon-display:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-size: 14px;
  color: #999;
  padding: 2px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-folder.wdr-collapsed #wdr-icon-display:before {
  content: "\e903";
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-folder.wdr-expanded #wdr-icon-display:before {
  content: "\e902";
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-level-folder #wdr-text-display {
  left: 52px;
  right: 20px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-level-folder.wdr-unchecked #wdr-moreicon-display {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  border: 1px solid #d5d5d5;
  background: #fff;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-level-folder.wdr-checked #wdr-moreicon-display {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  background: #555;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-level-folder.wdr-checked #wdr-moreicon-display:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  content: '\e901';
  color: #fff;
  font-size: 16px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-measures-folder:after {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e914";
  right: 4px;
  font-size: 24px;
  color: #DBDBDB;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-measures-folder #wdr-text-display {
  right: 20px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-measure:not(.wdr-calculated):after {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e914";
  right: 24px;
  font-size: 24px;
  color: #DBDBDB;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-measure:not(.wdr-calculated) #wdr-text-display {
  right: 50px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-calculated #wdr-calc-display {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer;
  right: 24px;
  font-size: 24px;
  color: #DBDBDB;
  width: 24px;
  height: 24px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-calculated #wdr-calc-display:hover {
  color: #999;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-calculated #wdr-calc-display:before {
  content: "\e914";
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-kpis-folder:after {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e94a";
  right: 8px;
  font-size: 16px;
  color: #DBDBDB;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-kpis-folder #wdr-text-display {
  right: 25px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-1 {
  padding-left: 20px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-1 #wdr-text-display {
  left: 42px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-1.wdr-level #wdr-text-display {
  left: 54px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-1.wdr-level-folder #wdr-text-display {
  left: 64px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-1.wdr-level #wdr-icon-display {
  left: 12px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-2 {
  padding-left: 30px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-2 #wdr-text-display {
  left: 52px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-2.wdr-level #wdr-text-display {
  left: 64px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-2.wdr-level-folder #wdr-text-display {
  left: 74px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-2.wdr-level #wdr-icon-display {
  left: 12px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-3 {
  padding-left: 40px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-3 #wdr-text-display {
  left: 62px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-3.wdr-level #wdr-text-display {
  left: 74px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-3.wdr-level-folder #wdr-text-display {
  left: 84px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-3.wdr-level #wdr-icon-display {
  left: 12px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-4 {
  padding-left: 50px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-4 #wdr-text-display {
  left: 72px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-4.wdr-level #wdr-text-display {
  left: 84px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-4.wdr-level-folder #wdr-text-display {
  left: 94px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-4.wdr-level #wdr-icon-display {
  left: 12px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-5 {
  padding-left: 60px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-5 #wdr-text-display {
  left: 82px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-5.wdr-level #wdr-text-display {
  left: 94px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-5.wdr-level-folder #wdr-text-display {
  left: 104px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-5.wdr-level #wdr-icon-display {
  left: 12px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-6 {
  padding-left: 70px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-6 #wdr-text-display {
  left: 92px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-6.wdr-level #wdr-text-display {
  left: 104px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-6.wdr-level-folder #wdr-text-display {
  left: 114px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-6.wdr-level #wdr-icon-display {
  left: 12px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-7 {
  padding-left: 80px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-7 #wdr-text-display {
  left: 102px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-7.wdr-level #wdr-text-display {
  left: 114px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-7.wdr-level-folder #wdr-text-display {
  left: 124px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-7.wdr-level #wdr-icon-display {
  left: 12px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-8 {
  padding-left: 90px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-8 #wdr-text-display {
  left: 112px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-8.wdr-level #wdr-text-display {
  left: 124px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-8.wdr-level-folder #wdr-text-display {
  left: 134px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-8.wdr-level #wdr-icon-display {
  left: 12px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-9 {
  padding-left: 100px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-9 #wdr-text-display {
  left: 122px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-9.wdr-level #wdr-text-display {
  left: 134px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-9.wdr-level-folder #wdr-text-display {
  left: 144px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-9.wdr-level #wdr-icon-display {
  left: 12px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-10 {
  padding-left: 110px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-10 #wdr-text-display {
  left: 132px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-10.wdr-level #wdr-text-display {
  left: 144px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-10.wdr-level-folder #wdr-text-display {
  left: 154px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-level-10.wdr-level #wdr-icon-display {
  left: 12px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-measures {
  position: relative;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-measures #wdr-text-display {
  width: calc(100% - 50px);
  width: -webkit-calc(100% - 50px);
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-measures #wdr-aggr-display {
  width: 32px;
  height: 100%;
  position: absolute;
  right: 30px;
  top: 0;
  cursor: pointer;
  padding-top: 7px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-measures #wdr-aggr-display:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  letter-spacing: -8px;
  content: "\e914\e902";
  font-size: 24px;
  color: #999;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-measures #wdr-aggr-display:hover:before {
  color: #555;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-pages #wdr-text-display,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-columns #wdr-text-display,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-rows #wdr-text-display {
  width: calc(100% - 20px);
  width: -webkit-calc(100% - 20px);
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-prompt {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px;
  color: #cecece;
  font-style: italic;
  text-align: center;
  z-index: -1;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-pages,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-cols {
  margin-bottom: 30px;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-pages,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-cols,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-rows,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-measures {
  height: -webkit-calc(50% - 15px);
  height: calc(50% - 15px);
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-pages .wdr-list-content,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-cols .wdr-list-content,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-rows .wdr-list-content,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-measures .wdr-list-content {
  height: -webkit-calc(100% - 23px);
  height: calc(100% - 23px);
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-pages .wdr-dragging-move,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-cols .wdr-dragging-move,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-rows .wdr-dragging-move,
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-measures .wdr-dragging-move {
  display: none;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-all {
  height: 100%;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap-all .wdr-list-content {
  height: -webkit-calc(100% - 33px);
  height: calc(100% - 33px);
}
#wdr-pivot-view #wdr-fields-view.wdr-flat-fields {
  min-width: 320px;
  width: 420px;
}
#wdr-pivot-view #wdr-fields-view.wdr-flat-fields .wdr-popup-header .wdr-ui-col:first-child {
  margin-right: 20px;
}
#wdr-pivot-view #wdr-fields-view.wdr-flat-fields .wdr-popup-header #wdr-btn-add-measure {
  min-width: 40px;
  width: 40px;
  padding: 0;
}
#wdr-pivot-view #wdr-fields-view.wdr-flat-fields .wdr-popup-content .wdr-list-wrap .wdr-list-header {
  padding: 10px 9px;
}
#wdr-pivot-view #wdr-fields-view.wdr-flat-fields .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-ui-checkbox span.wdr-ui-label {
  color: #111;
  padding-left: 26px;
}
#wdr-pivot-view #wdr-fields-view.wdr-flat-fields .wdr-popup-content .wdr-list-wrap .wdr-list-header .wdr-ui-checkbox.wdr-selected {
  font-weight: bold;
}
#wdr-pivot-view #wdr-fields-view.wdr-flat-fields .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies {
  max-height: 303px;
}
#wdr-pivot-view #wdr-fields-view.wdr-flat-fields .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li #wdr-text-display {
  left: 35px;
}
#wdr-pivot-view #wdr-fields-view.wdr-flat-fields .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies .wdr-dragging-move {
  display: none;
}
#wdr-pivot-view #wdr-fields-view.wdr-layout-tablet .wdr-popup-header .wdr-ui-col:first-child {
  margin-right: 20px;
}
#wdr-pivot-view #wdr-fields-view.wdr-layout-tablet .wdr-list-wrap .wdr-list-header.wdr-list-header-wide .wdr-list-header-label-wrap {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
#wdr-pivot-view #wdr-fields-view.wdr-layout-tablet .wdr-list-wrap .wdr-list-header.wdr-list-header-wide span.wdr-ui-label {
  display: block;
}
#wdr-pivot-view #wdr-fields-view.wdr-layout-tablet .wdr-list-wrap .wdr-list-header.wdr-list-header-wide #wdr-btn-collapse-expand-all {
  margin-left: 0px;
}
#wdr-pivot-view #wdr-fields-view.wdr-layout-mobile #wdr-btn-add-measure {
  font-size: 0;
  min-width: 40px;
  width: 40px;
  padding: 0;
}
#wdr-pivot-view #wdr-fields-view.wdr-layout-mobile .wdr-ui-col-3 {
  margin-right: 12px;
  width: calc(33.33% - 8px);
  width: -webkit-calc(33.33% - 8px);
}
#wdr-pivot-view #wdr-fields-view.wdr-layout-mobile .wdr-ui-col-3:nth-child(3n+3) {
  margin-right: 0;
}
#wdr-pivot-view #wdr-fields-view.wdr-layout-mobile #wdr-wrap-pages,
#wdr-pivot-view #wdr-fields-view.wdr-layout-mobile #wdr-wrap-columns {
  margin-bottom: 12px;
}
#wdr-pivot-view #wdr-fields-view.wdr-layout-mobile #wdr-wrap-pages,
#wdr-pivot-view #wdr-fields-view.wdr-layout-mobile #wdr-wrap-columns,
#wdr-pivot-view #wdr-fields-view.wdr-layout-mobile #wdr-wrap-measures,
#wdr-pivot-view #wdr-fields-view.wdr-layout-mobile #wdr-wrap-rows {
  height: calc(50% - 6px);
  height: -webkit-calc(50% - 6px);
}
#wdr-pivot-view #wdr-fields-view.wdr-layout-mobile-small .wdr-ui-btns-row .wdr-ui-btn:not(#wdr-btn-add-measure) {
  width: calc(50% - 40px);
  width: -webkit-calc(50% - 40px);
}
#wdr-pivot-view #wdr-aggregations-view {
  width: 170px;
  background: #fff;
  z-index: 9;
  border: 1px solid #d5d5d5;
  position: absolute;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 0;
}
#wdr-pivot-view #wdr-aggregations-view .wdr-arrow {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  position: absolute;
  right: 0;
}
#wdr-pivot-view #wdr-aggregations-view .wdr-arrow:after,
#wdr-pivot-view #wdr-aggregations-view .wdr-arrow:before {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
}
#wdr-pivot-view #wdr-aggregations-view .wdr-arrow:before {
  border-width: 5.5px 0 5.5px 6px;
  border-color: transparent transparent transparent #d5d5d5;
}
#wdr-pivot-view #wdr-aggregations-view .wdr-arrow:after {
  border-width: 4.5px 0 4.5px 5px;
  border-color: transparent transparent transparent #f1f1f1;
}
#wdr-pivot-view #wdr-aggregations-view ul.wdr-ui-list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
#wdr-pivot-view #wdr-aggregations-view ul.wdr-ui-list li {
  padding: 10px 9px;
  border-bottom: 1px solid #DBDBDB;
  cursor: pointer;
  position: relative;
}
#wdr-pivot-view #wdr-aggregations-view ul.wdr-ui-list li:last-child {
  border-bottom: none;
}
#wdr-pivot-view #wdr-aggregations-view ul.wdr-ui-list li:hover {
  background: #f1f1f1;
}
#wdr-pivot-view #wdr-aggregations-view ul.wdr-ui-list li.wdr-selected {
  background: #f1f1f1;
}
#wdr-pivot-view #wdr-aggregations-view ul.wdr-ui-list li.wdr-selected:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  content: '\e901';
  right: 24px;
  top: 8px;
  color: #999;
  font-size: 18px;
}
#wdr-pivot-view div.wdr-ui-modal-overlay.wdr-dt-fields-overlay {
  z-index: 13;
}
#wdr-pivot-view div.wdr-ui-popup.wdr-dt-fields {
  z-index: 14;
}
#wdr-pivot-view div.wdr-ui-popup.wdr-dt-fields #wdr-btn-add-measure {
  display: none;
}
/* Drill trough view */
#wdr-pivot-view #wdr-drillthrough-view {
  overflow: hidden;
  min-width: 320px;
}
#wdr-pivot-view #wdr-drillthrough-view .wdr-header-container {
  position: relative;
  margin-bottom: 10px;
}
#wdr-pivot-view #wdr-drillthrough-view .wdr-details-container {
  margin-bottom: 20px;
}
#wdr-pivot-view #wdr-drillthrough-view .wdr-details-container .wdr-ui-label {
  margin-right: 20px;
  display: inline-block;
  max-width: 33%;
  vertical-align: top;
}
#wdr-pivot-view #wdr-drillthrough-view .wdr-details-container .wdr-ui-label b {
  color: #111;
}
#wdr-pivot-view #wdr-drillthrough-view .wdr-details-container .wdr-ui-label:last-child {
  margin-right: 0;
}
#wdr-pivot-view #wdr-drillthrough-view .wdr-grid-container {
  border: 1px solid #d5d5d5;
  position: relative;
}
#wdr-pivot-view #wdr-drillthrough-view .wdr-popup-title {
  padding-right: 30px;
}
#wdr-pivot-view #wdr-drillthrough-view .wdr-ui-btn-close {
  position: absolute;
  top: 0;
  right: 0;
}
#wdr-pivot-view input[type=text].wdr-ui-text-input.wdr-editing-cell {
  position: absolute;
  font-size: 12px;
}
#wdr-pivot-view textarea.wdr-ui-text-area.wdr-editing-cell {
  position: absolute;
  font-size: 12px;
  padding-left: 4px;
  padding-top: 7px;
  box-sizing: border-box;
  z-index: 1;
}
/* Drill trough view end */
/* Calculated view */
#wdr-pivot-view #wdr-calculated-view {
  z-index: 14;
  min-width: 320px;
  width: 480px;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-header .wdr-ui-col:first-child {
  margin-right: 30px;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-header #wdr-remove-btn {
  min-width: 40px;
  padding: 0;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-header #wdr-remove-btn:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  font-size: 30px;
  right: 4px;
  color: #999;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-header #wdr-remove-btn:hover:before {
  color: #555;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content {
  position: relative;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-name-input {
  margin-bottom: 20px;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-lst-measures {
  border: 1px solid #d5d5d5;
  margin-bottom: 20px;
  height: 191px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-lst-measures li {
  border-bottom: 1px solid #DBDBDB;
  padding: 10px 9px;
  position: relative;
  cursor: move;
  background: #fff;
  height: 38px;
  white-space: nowrap;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-lst-measures li span {
  vertical-align: middle;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-lst-measures li #wdr-text-display {
  font-size: 14px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 50px);
  width: -webkit-calc(100% - 50px);
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-lst-measures li #wdr-aggr-display {
  width: 32px;
  height: 100%;
  position: absolute;
  right: 30px;
  top: 0;
  cursor: pointer;
  padding-top: 7px;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-lst-measures li #wdr-aggr-display:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  letter-spacing: -8px;
  content: "\e914\e902";
  font-size: 24px;
  color: #999;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-lst-measures li #wdr-aggr-display:hover:before {
  color: #555;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-lst-measures li #wdr-drag-handle {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: #d5d5d5;
  font-size: 20px;
  padding: 8px;
  right: 0;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-lst-measures li #wdr-drag-handle:before {
  content: "\e910";
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-func-btn-group {
  margin-top: 20px;
  margin-bottom: 20px;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-func-btn-group .wdr-calc-action {
  height: 38px;
  line-height: 36px;
  font-size: 14px;
  background: #999;
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content #wdr-func-btn-group .wdr-calc-action:hover {
  opacity: 0.85;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content .wdr-formula-wrap {
  position: relative;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content .wdr-formula-wrap #wdr-formula-input {
  width: 100%;
  height: 80px;
  font-size: 14px;
  border: 1px solid #d5d5d5;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content .wdr-formula-wrap #wdr-formula-input.wdr-droppable-over {
  background: #fbfbfb;
  border: 1px solid #999;
}
#wdr-pivot-view #wdr-calculated-view .wdr-popup-content .wdr-formula-wrap .wdr-prompt {
  position: absolute;
  bottom: 10px;
  pointer-events: none;
  left: 0;
  width: 100%;
  text-align: center;
  color: #cecece;
  font-style: italic;
}
#wdr-pivot-view #wdr-calculated-view.wdr-layout-mobile .wdr-popup-header .wdr-ui-col:first-child {
  margin-right: 10px;
}
#wdr-pivot-view div.wdr-ui-modal-overlay.wdr-calculated-view-overlay {
  z-index: 13;
}
/* PRELOADER VIEW */
#wdr-pivot-view div.wdr-ui-modal-overlay.wdr-overlay-preloader {
  z-index: 20;
}
#wdr-pivot-view #wdr-preloader-view {
  z-index: 21;
  width: 320px;
  height: 100px;
}
#wdr-pivot-view #wdr-preloader-view #wdr-spinner {
  position: relative;
}
#wdr-pivot-view #wdr-preloader-view #wdr-spinner:before {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  content: "\e97b";
  font-size: 31px;
  left: 7px;
  top: 10px;
  color: #999;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-transition: rotate(3600deg);
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#wdr-pivot-view #wdr-preloader-view #wdr-message-label {
  display: block;
  padding-left: 70px;
  padding-top: 8px;
}
#wdr-pivot-view #wdr-preloader-view #wdr-details-label {
  display: block;
  color: #999;
  padding-left: 70px;
  padding-top: 2px;
  font-size: 12px;
}
/* PRELOADER END */
/* ALERT VIEW */
#wdr-pivot-view div.wdr-ui-modal-overlay.wdr-overlay-alert {
  z-index: 16;
}
#wdr-pivot-view #wdr-alert-view {
  z-index: 17;
}
#wdr-pivot-view #wdr-alert-view .wdr-content {
  margin-left: 100px;
  max-width: 300px;
}
#wdr-pivot-view #wdr-alert-view .wdr-popup-title {
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#wdr-pivot-view #wdr-alert-view .wdr-alert-icon {
  position: absolute;
  left: 50px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
#wdr-pivot-view #wdr-alert-view .wdr-ui-btns-row {
  margin-top: 10px;
}
#wdr-pivot-view #wdr-alert-view .wdr-ui-btns-row .wdr-ui-btn {
  margin-top: 10px;
}
#wdr-pivot-view #wdr-alert-view .wdr-circle {
  display: block;
  border-radius: 50%;
  background: #999;
  width: 60px;
  height: 60px;
  position: relative;
  margin-left: -10px;
}
#wdr-pivot-view #wdr-alert-view .wdr-circle .wdr-ui-icon {
  color: #fff;
  font-size: 38px;
  padding: 11px;
}
#wdr-pivot-view #wdr-alert-view .wdr-hexagon {
  display: block;
  position: relative;
  width: 32.33px;
  height: 56px;
  background-color: #999;
  margin: 16.17px 0;
}
#wdr-pivot-view #wdr-alert-view .wdr-hexagon .wdr-ui-icon {
  color: #fff;
  font-size: 38px;
  top: 8px;
  left: -3px;
}
#wdr-pivot-view #wdr-alert-view .wdr-hexagon:before,
#wdr-pivot-view #wdr-alert-view .wdr-hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
}
#wdr-pivot-view #wdr-alert-view .wdr-hexagon:before {
  left: 100%;
  border-left: 16.17px solid #999;
}
#wdr-pivot-view #wdr-alert-view .wdr-hexagon:after {
  right: 100%;
  width: 0;
  border-right: 16.17px solid #999;
}
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap {
  position: relative;
}
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap .wdr-ui-icon {
  color: #fff;
  font-size: 38px;
  top: -2px;
  left: -1px;
}
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap .wdr-triangle {
  position: relative;
  background-color: #999;
  text-align: left;
  display: block;
  margin-top: -7px;
}
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:before,
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:after {
  content: '';
  position: absolute;
  background-color: inherit;
}
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap .wdr-triangle,
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:before,
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:after {
  width: 35px;
  height: 35px;
  border-top-right-radius: 30%;
}
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap .wdr-triangle {
  -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  -ms-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:before {
  -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  -ms-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}
#wdr-pivot-view #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:after {
  -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  -ms-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}
#wdr-pivot-view #wdr-alert-view.wdr-layout-mobile-small .wdr-content {
  margin-left: 0;
}
#wdr-pivot-view #wdr-alert-view.wdr-layout-mobile-small .wdr-alert-icon {
  display: none;
}
/* ALERT VIEW END*/
.wdr-noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#wdr-pivot-view #wdr-context-menu {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  background-color: #fff;
  border: 1px solid #999;
  z-index: 20;
}
#wdr-pivot-view #wdr-context-menu .wdr-ui-list {
  min-width: 180px;
}
#wdr-pivot-view #wdr-context-menu .wdr-ui-list li {
  padding: 10px;
  border-bottom: 1px solid #DBDBDB;
  cursor: pointer;
}
#wdr-pivot-view #wdr-context-menu .wdr-ui-list li:last-child {
  border-bottom: none;
}
#wdr-pivot-view #wdr-context-menu .wdr-ui-list li:hover {
  background-color: #f1f1f1;
}
/* NEW GRID */
#wdr-pivot-view .wdr-grid-layout {
  position: relative;
  overflow: hidden;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-scroll-pane {
  overflow: auto;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-scroll-pane div.wdr-scroll-content {
  position: relative;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-scroll-pane div.wdr-scroll-placeholder {
  position: relative;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell {
  background-color: #fff;
  color: #111;
  box-sizing: border-box;
  vertical-align: top;
  border-right: 1px solid #DBDBDB;
  border-bottom: 1px solid #DBDBDB;
  padding: 7px 4px;
  /*line-height: 22px;*/
}
#wdr-pivot-view .wdr-grid-layout div.wdr-row {
  white-space: nowrap;
  box-sizing: border-box;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-row > div {
  display: inline-block;
}
#wdr-pivot-view .wdr-grid-layout #wdr-rows-sheet div.wdr-row {
  display: flex;
}
#wdr-pivot-view .wdr-grid-layout #wdr-rows-sheet div.wdr-row > div.wdr-sheet-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-scroll-pane div.wdr-scroll-content {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  /*pointer-events: none;*/
}
#wdr-pivot-view .wdr-grid-layout #wdr-data-sheet,
#wdr-pivot-view .wdr-grid-layout #wdr-rows-sheet,
#wdr-pivot-view .wdr-grid-layout #wdr-cols-sheet,
#wdr-pivot-view .wdr-grid-layout #wdr-sheet-headers {
  position: absolute;
  overflow: hidden;
  background-color: #fff;
}
#wdr-pivot-view .wdr-grid-layout #wdr-data-sheet {
  border-top: 1px solid #DBDBDB;
  border-left: 1px solid #DBDBDB;
}
#wdr-pivot-view .wdr-grid-layout .wdr-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#wdr-pivot-view .wdr-grid-layout #wdr-data-sheet .wdr-cell {
  text-align: right;
}
#wdr-pivot-view .wdr-grid-layout #wdr-cols-sheet .wdr-scroll-pane,
#wdr-pivot-view .wdr-grid-layout #wdr-rows-sheet .wdr-scroll-pane {
  overflow: hidden;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-scroll-pane div.wdr-scroll-content .wdr-cell {
  position: relative;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-scroll-pane div.wdr-scroll-placeholder .wdr-cell {
  position: absolute;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-header {
  background-color: #f1f1f1;
  color: #111;
  border-right: 1px solid #DBDBDB;
  border-bottom: 1px solid #DBDBDB;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-filter-header {
  cursor: pointer;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-total,
#wdr-pivot-view .wdr-grid-layout div.wdr-grand-total {
  font-weight: bold;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-header.wdr-total {
  font-weight: normal;
}
#wdr-pivot-view .wdr-grid-layout.wdr-flat-view div.wdr-total,
#wdr-pivot-view .wdr-grid-layout.wdr-flat-view div.wdr-grand-total {
  text-align: right;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filters {
  position: absolute;
  background-color: #DBDBDB;
  color: #111;
}
#wdr-pivot-view .wdr-grid-layout #wdr-cols-filter {
  border-right: 1px solid #DBDBDB;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filters .wdr-header,
#wdr-pivot-view .wdr-grid-layout.wdr-flat-view .wdr-header {
  background-color: #DBDBDB;
  color: #111;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-filter-header,
#wdr-pivot-view .wdr-grid-layout a.wdr-filter-header {
  padding-right: 20px;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-filter-header.wdr-filtered,
#wdr-pivot-view .wdr-grid-layout a.wdr-filter-header.wdr-filtered {
  padding-right: 35px;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-filter-header:hover {
  background-color: #cecece;
}
#wdr-pivot-view .wdr-grid-layout i.wdr-icon {
  position: absolute;
  height: 100%;
  width: 16px;
  top: 0;
}
#wdr-pivot-view .wdr-grid-layout i.wdr-icon:before {
  font-family: webdatarocks-icons;
  font-weight: normal;
  font-size: 15px;
  color: #999;
  position: absolute;
  top: 6px;
}
#wdr-pivot-view .wdr-grid-layout.wdr-flat-view i.wdr-icon:before {
  color: #999;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filter-header i.wdr-filter-icon {
  right: 2px;
  pointer-events: none;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filter-header i.wdr-filter-icon:before {
  content: "\e915";
  color: #999;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filter-header.wdr-filtered i.wdr-filtered-icon {
  right: 16px;
  pointer-events: none;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filter-header.wdr-filtered i.wdr-filtered-icon:before {
  content: "\e90e";
  color: #999;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filter-header.wdr-dragging-move {
  opacity: 0.5;
}
#wdr-pivot-view .wdr-grid-layout .wdr-header-drop-indicator {
  background-color: #555;
}
#wdr-pivot-view .wdr-grid-layout .wdr-draggable:not(.wdr-filter-header) {
  cursor: move;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filters#wdr-cols-filter .wdr-header-drop-indicator {
  display: inline-block;
  width: 3px;
  height: 100%;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filters#wdr-rows-filter .wdr-header-drop-indicator {
  display: block;
  width: 100%;
  height: 3px;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filters#wdr-page-filter .wdr-header-drop-indicator {
  float: none;
  display: inline-block;
  width: 3px;
  height: 40px;
  vertical-align: top;
  margin-right: 5px;
}
#wdr-pivot-view .wdr-grid-layout.wdr-classic-view .wdr-filters#wdr-rows-filter .wdr-header-drop-indicator {
  display: inline-block;
  width: 3px;
  height: 30px;
}
#wdr-pivot-view .wdr-grid-layout.wdr-flat-view .wdr-header-drop-indicator {
  position: absolute;
  width: 3px;
  height: 30px;
}
/* pages filter */
#wdr-pivot-view .wdr-grid-layout .wdr-filters#wdr-page-filter {
  padding: 5px 0 0 5px;
  border-bottom: 1px solid #d5d5d5;
  background-color: #f1f1f1;
  white-space: nowrap;
  overflow-x: auto;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filters#wdr-page-filter a.wdr-filter-header {
  text-align: left;
  border: none;
  background-color: #DBDBDB;
  margin-bottom: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 20px;
  width: auto;
  width: initial;
  min-width: 80px;
  padding-left: 5px;
  line-height: 15px;
  white-space: nowrap;
  font-weight: bold !important;
  display: inline-block;
  float: none;
  cursor: pointer;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filters#wdr-page-filter a.wdr-filter-header:hover {
  background-color: #cecece;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filters#wdr-page-filter i.wdr-icon:before {
  top: 5px;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filters a.wdr-filter-header .wdr-filter-desc {
  display: block;
  font-weight: normal !important;
  text-transform: none;
  color: #888;
  font-size: 10px;
  position: absolute;
  bottom: 5px;
}
/* levels */
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-1 {
  padding-left: 15px;
  background-position: 12px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-2 {
  padding-left: 27px;
  background-position: 24px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-3 {
  padding-left: 39px;
  background-position: 36px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-4 {
  padding-left: 51px;
  background-position: 48px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-5 {
  padding-left: 63px;
  background-position: 60px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-6 {
  padding-left: 75px;
  background-position: 72px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-7 {
  padding-left: 87px;
  background-position: 84px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-8 {
  padding-left: 99px;
  background-position: 96px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-9 {
  padding-left: 111px;
  background-position: 108px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-10 {
  padding-left: 123px;
  background-position: 120px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-11 {
  padding-left: 135px;
  background-position: 132px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-12 {
  padding-left: 147px;
  background-position: 144px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-13 {
  padding-left: 159px;
  background-position: 156px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-14 {
  padding-left: 171px;
  background-position: 168px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-15 {
  padding-left: 183px;
  background-position: 180px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-16 {
  padding-left: 195px;
  background-position: 192px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-17 {
  padding-left: 207px;
  background-position: 204px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-18 {
  padding-left: 219px;
  background-position: 216px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-19 {
  padding-left: 231px;
  background-position: 228px center;
}
#wdr-pivot-view .wdr-grid-layout.wdr-compact-view .wdr-header-r.wdr-level-20 {
  padding-left: 243px;
  background-position: 240px center;
}
/* collapsed / expanded */
#wdr-pivot-view .wdr-grid-layout div.wdr-collapsed,
#wdr-pivot-view .wdr-grid-layout div.wdr-expanded {
  cursor: pointer;
}
#wdr-pivot-view .wdr-grid-layout .wdr-collapsed .wdr-collapsed-icon,
#wdr-pivot-view .wdr-grid-layout .wdr-expanded .wdr-expanded-icon {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 11px;
}
#wdr-pivot-view .wdr-grid-layout .wdr-collapsed .wdr-collapsed-icon:before,
#wdr-pivot-view .wdr-grid-layout .wdr-expanded .wdr-expanded-icon:before {
  top: 0;
  left: -4px;
}
#wdr-pivot-view .wdr-grid-layout .wdr-collapsed .wdr-collapsed-icon:before {
  content: "\e903";
}
#wdr-pivot-view .wdr-grid-layout .wdr-expanded .wdr-expanded-icon:before {
  content: "\e902";
}
/* sort */
#wdr-pivot-view .wdr-grid-layout i.wdr-icon.wdr-sort-icon,
#wdr-pivot-view #wdr-drillthrough-view i.wdr-icon.wdr-sort-icon {
  cursor: pointer;
  right: 0;
}
#wdr-pivot-view .wdr-grid-layout i.wdr-icon.wdr-sort-icon:before,
#wdr-pivot-view #wdr-drillthrough-view i.wdr-icon.wdr-sort-icon:before {
  font-size: 15px;
}
#wdr-pivot-view .wdr-grid-layout .wdr-cell.wdr-v-sort,
#wdr-pivot-view .wdr-grid-layout .wdr-cell.wdr-h-sort {
  padding-right: 16px;
}
#wdr-pivot-view .wdr-grid-layout .wdr-v-sort:hover .wdr-v-sort-icon:before,
#wdr-pivot-view .wdr-grid-layout .wdr-v-sort .wdr-v-sort-asc:before,
#wdr-pivot-view .wdr-grid-layout .wdr-v-sort .wdr-v-sort-desc:before,
#wdr-pivot-view .wdr-grid-layout .wdr-h-sort:hover .wdr-h-sort-icon:before,
#wdr-pivot-view .wdr-grid-layout .wdr-h-sort .wdr-h-sort-asc:before,
#wdr-pivot-view .wdr-grid-layout .wdr-h-sort .wdr-h-sort-desc:before {
  content: "\e900";
}
#wdr-pivot-view .wdr-grid-layout .wdr-v-sort .wdr-v-sort-asc:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
#wdr-pivot-view .wdr-grid-layout .wdr-h-sort:hover .wdr-h-sort-icon:before,
#wdr-pivot-view .wdr-grid-layout .wdr-h-sort .wdr-h-sort-desc:before {
  right: 1px;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  transform: rotate(270deg);
  -o-transform: rotate(270deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
#wdr-pivot-view .wdr-grid-layout .wdr-h-sort .wdr-h-sort-asc:before {
  right: 2px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
/* drill down links */
#wdr-pivot-view .wdr-grid-layout span.wdr-hierarchy-link {
  white-space: nowrap;
  color: #888;
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;
}
#wdr-pivot-view .wdr-grid-layout span.wdr-hierarchy-link:before {
  text-indent: 0;
  margin-right: 2px;
  display: inline-block;
}
#wdr-pivot-view .wdr-grid-layout .wdr-drilled-up span.wdr-hierarchy-link:before {
  content: "+";
}
#wdr-pivot-view .wdr-grid-layout .wdr-drilled-down span.wdr-hierarchy-link:before {
  content: "-";
}
/* sheet headers */
#wdr-pivot-view .wdr-grid-layout div.wdr-sheet-header,
#wdr-pivot-view .wdr-grid-layout .wdr-filters div.wdr-sheet-header {
  border-right: 1px solid #DBDBDB;
  border-bottom: 1px solid #DBDBDB;
  color: #999;
  background-color: #f1f1f1;
  text-align: center;
  line-height: 23px;
  padding: 0 0;
}
#wdr-pivot-view .wdr-grid-layout #wdr-sheet-headers .wdr-sheet-header {
  display: inline-block;
}
/* KPI cells */
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi:after {
  display: block;
  position: absolute;
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  position: relative;
  color: #999;
  font-size: 16px;
  padding-top: 3px;
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi-trend-bad-icon:after {
  content: "\ea3e";
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi-trend-risk-icon:after {
  content: "\ea3d";
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi-trend-ok-icon:after {
  content: "\ea3c";
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi-trend-rising-icon:after {
  content: "\ea3b";
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi-trend-good-icon:after {
  content: "\ea3a";
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi-status-bad-icon:after {
  content: "\e951";
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi-status-risk-icon:after {
  content: "\e954";
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi-status-ok-icon:after {
  content: "\e952";
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi-status-rising-icon:after {
  content: "\e953";
}
#wdr-pivot-view .wdr-grid-layout div.wdr-cell.wdr-kpi-status-good-icon:after {
  content: "\e950";
}
/* member properties */
#wdr-pivot-view .wdr-grid-layout div.wdr-cell .wdr-member-property-label {
  font-style: italic;
}
/* selection canvas */
#wdr-pivot-view .wdr-grid-layout .wdr-sheet-selection-canvas {
  position: absolute;
  background-color: rgba(66, 133, 244, 0.1);
  pointer-events: none;
  box-sizing: border-box;
  border: 1px solid #4285f4;
  z-index: 1;
}
#wdr-pivot-view .wdr-grid-layout .wdr-sheet-selection-canvas.wdr-clear-border {
  border: none;
}
#wdr-pivot-view .wdr-grid-layout .wdr-sheet-selection-canvas.wdr-clear-border-right {
  border-right: none;
}
#wdr-pivot-view .wdr-grid-layout .wdr-sheet-selection-canvas.wdr-clear-border-left {
  border-left: none;
}
#wdr-pivot-view .wdr-grid-layout .wdr-sheet-selection-canvas.wdr-clear-border-top {
  border-top: none;
}
#wdr-pivot-view .wdr-grid-layout .wdr-sheet-selection-canvas.wdr-clear-border-bottom {
  border-bottom: none;
}
#wdr-pivot-view .wdr-grid-layout .wdr-auto-calculation-bar {
  position: absolute;
  background-color: #888888;
  opacity: 1;
  pointer-events: none;
  box-sizing: border-box;
  z-index: 2;
  height: 17px;
  overflow: hidden;
}
#wdr-pivot-view .wdr-grid-layout .wdr-auto-calculation-bar .wdr-auto-calculation-bar-conainer {
  overflow: hidden;
  white-space: nowrap;
}
#wdr-pivot-view .wdr-grid-layout .wdr-auto-calculation-bar .wdr-auto-calculation-bar-content {
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
}
#wdr-pivot-view .wdr-grid-layout .wdr-auto-calculation-bar .wdr-auto-calculation-bar-content-text {
  color: white;
  text-transform: uppercase;
  margin-right: 5px;
  float: left;
  font-size: 11px;
  line-height: 17px;
}
#wdr-pivot-view .wdr-grid-layout .wdr-auto-calculation-bar .wdr-auto-calculation-bar-content-results {
  color: white;
  font-weight: bold;
  display: inline-block;
  font-size: 11px;
  line-height: 17px;
}
#wdr-pivot-view .wdr-grid-layout .wdr-sheet-selection-header {
  border: none;
}
/* flat view */
#wdr-pivot-view .wdr-grid-layout.wdr-flat-view .wdr-filter-header {
  padding-left: 16px;
}
#wdr-pivot-view .wdr-grid-layout.wdr-flat-view .wdr-filter-header i.wdr-filter-icon {
  left: 0;
}
/* resize handles */
#wdr-pivot-view .wdr-grid-layout .wdr-resize-handles {
  position: absolute;
}
#wdr-pivot-view .wdr-grid-layout .wdr-resize-handles .wdr-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
#wdr-pivot-view .wdr-grid-layout .wdr-resize-handles .wdr-indicator {
  background-color: #4285f4;
  width: 1px;
  height: 1px;
  position: absolute;
  z-index: 1;
}
#wdr-pivot-view .wdr-grid-layout .wdr-resize-handles .wdr-handle {
  position: absolute;
  z-index: 1;
}
#wdr-pivot-view .wdr-grid-layout .wdr-resize-handles .wdr-handle:hover {
  background-color: #4285f4;
}
#wdr-pivot-view .wdr-grid-layout .wdr-resize-handles .wdr-handle.wdr-active {
  background-color: #4285f4;
}
#wdr-pivot-view .wdr-grid-layout .wdr-resize-handles#wdr-cols-resize .wdr-handle {
  width: 7px;
  margin-left: -3px;
  height: 100%;
  min-height: 25px;
  cursor: col-resize;
}
#wdr-pivot-view .wdr-grid-layout .wdr-resize-handles#wdr-rows-resize .wdr-handle {
  height: 7px;
  margin-top: -3px;
  width: 100%;
  min-width: 25px;
  cursor: row-resize;
}
/*
 * 	Toolbar for webdatarocks Pivot Table Component
 */
#wdr-toolbar-wrapper [class^="wdr-"],
#wdr-toolbar-wrapper [class*=" wdr-"] {
  color: #111;
  line-height: 1;
}
#wdr-toolbar-wrapper [class^="wdr-"] strong,
#wdr-toolbar-wrapper [class*=" wdr-"] strong {
  font-weight: bold;
}
#wdr-toolbar-wrapper [class^="wdr-"] a,
#wdr-toolbar-wrapper [class*=" wdr-"] a {
  text-decoration: none;
}
#wdr-toolbar-wrapper [class^="wdr-"] *,
[class*=" wdr-"] * {
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#wdr-toolbar-wrapper [class^="wdr-"] ul,
#wdr-toolbar-wrapper [class^="wdr-"] ol,
#wdr-toolbar-wrapper [class^="wdr-"] p,
#wdr-toolbar-wrapper [class*=" wdr-"] ul,
#wdr-toolbar-wrapper [class*=" wdr-"] ol,
#wdr-toolbar-wrapper [class*=" wdr-"] p {
  list-style: none;
  margin: 0;
  padding: 0;
}
#wdr-toolbar-wrapper [class^="wdr-icon-"]:before,
#wdr-toolbar-wrapper [class*=" wdr-icon-"]:before,
#wdr-toolbar-wrapper .wdr-icon:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-button-add .wdr-icon:before,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-btn .wdr-icon,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-currentmark,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-currentmark:before {
  font-size: 30px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-weight: normal;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-condition-row .wdr-cr-delete {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
/* TOOLBAR */
#wdr-toolbar-wrapper [class^="wdr-"],
#wdr-toolbar-wrapper [class*=" wdr-"] {
  color: #111;
  line-height: 1;
}
#wdr-toolbar-wrapper [class^="wdr-"] strong,
#wdr-toolbar-wrapper [class*=" wdr-"] strong {
  font-weight: bold;
}
#wdr-toolbar-wrapper [class^="wdr-"] a,
#wdr-toolbar-wrapper [class*=" wdr-"] a {
  text-decoration: none;
}
#wdr-toolbar-wrapper [class^="wdr-"] *,
[class*=" wdr-"] * {
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#wdr-toolbar-wrapper [class^="wdr-"] ul,
#wdr-toolbar-wrapper [class^="wdr-"] ol,
#wdr-toolbar-wrapper [class^="wdr-"] p,
#wdr-toolbar-wrapper [class*=" wdr-"] ul,
#wdr-toolbar-wrapper [class*=" wdr-"] ol,
#wdr-toolbar-wrapper [class*=" wdr-"] p {
  list-style: none;
  margin: 0;
  padding: 0;
}
#wdr-toolbar-wrapper [class^="wdr-icon-"]:before,
#wdr-toolbar-wrapper [class*=" wdr-icon-"]:before,
#wdr-toolbar-wrapper .wdr-icon:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-button-add .wdr-icon:before,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-btn .wdr-icon,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-currentmark,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-currentmark:before {
  font-size: 30px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-weight: normal;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-condition-row .wdr-cr-delete {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
/* TOOLBAR */
#wdr-toolbar-wrapper {
  height: 80px;
}
#wdr-toolbar-wrapper #wdr-toolbar {
  width: 100%;
  height: 78px;
  list-style: none;
  background: #fff;
  white-space: nowrap;
}
#wdr-toolbar-wrapper #wdr-toolbar,
#wdr-toolbar-wrapper #wdr-toolbar div,
#wdr-toolbar-wrapper #wdr-toolbar span,
#wdr-toolbar-wrapper #wdr-toolbar p,
#wdr-toolbar-wrapper #wdr-toolbar a,
#wdr-toolbar-wrapper #wdr-toolbar table,
#wdr-toolbar-wrapper #wdr-toolbar table th,
#wdr-toolbar-wrapper #wdr-toolbar table tr,
#wdr-toolbar-wrapper #wdr-toolbar table td,
#wdr-toolbar-wrapper #wdr-toolbar ul,
#wdr-toolbar-wrapper #wdr-toolbar li,
#wdr-toolbar-wrapper #wdr-toolbar input,
#wdr-toolbar-wrapper #wdr-toolbar textarea,
#wdr-toolbar-wrapper #wdr-toolbar select {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #888;
  text-align: left;
  padding: 0;
  margin: 0;
  font-weight: normal;
  text-shadow: none;
}
/* TABS */
#wdr-toolbar-wrapper #wdr-toolbar > li {
  display: inline-block;
}
#wdr-toolbar-wrapper #wdr-toolbar li:before {
  content: none;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown-content li {
  float: none;
  width: 140px;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-toolbar-group-right {
  position: absolute;
  right: 0;
}
#wdr-toolbar-wrapper #wdr-toolbar li a svg,
#wdr-toolbar-wrapper #wdr-toolbar li a svg path {
  transform: translateZ(0);
  fill: #999;
}
#wdr-toolbar-wrapper #wdr-toolbar li a:hover svg {
  fill: #555;
}
#wdr-toolbar-wrapper #wdr-toolbar > li > a div.wdr-svg-icon,
#wdr-toolbar-wrapper #wdr-toolbar > .wdr-toolbar-group-right > li > a div.wdr-svg-icon {
  position: absolute;
  text-align: center;
  bottom: 30px;
  width: 100%;
}
#wdr-toolbar-wrapper #wdr-toolbar > li > a span,
#wdr-toolbar-wrapper #wdr-toolbar > .wdr-toolbar-group-right > li > a span {
  position: absolute;
  top: 55px;
  line-height: 12px;
  left: 0;
  right: 0;
}
#wdr-toolbar-wrapper #wdr-toolbar #wdr-tab-format div.wdr-svg-icon {
  margin-left: 4px;
}
#wdr-toolbar-wrapper #wdr-toolbar #wdr-tab-format .wdr-dropdown-content li {
  width: 210px;
}
#wdr-toolbar-wrapper #wdr-toolbar #wdr-tab-format .wdr-dropdown-content span {
  left: 62px;
}
#wdr-toolbar-wrapper #wdr-alert-view {
  z-index: 17;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-content {
  margin-left: 100px;
  max-width: 300px;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-popup-title {
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-alert-icon {
  position: absolute;
  left: 50px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-ui-btns-row {
  margin-top: 10px;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-ui-btns-row .wdr-ui-btn {
  margin-top: 10px;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-circle {
  display: block;
  border-radius: 50%;
  background: #999;
  width: 60px;
  height: 60px;
  position: relative;
  margin-left: -10px;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-circle .wdr-ui-icon {
  color: #fff;
  font-size: 38px;
  padding: 11px;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-hexagon {
  display: block;
  position: relative;
  width: 32.33px;
  height: 56px;
  background-color: #999;
  margin: 16.17px 0;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-hexagon .wdr-ui-icon {
  color: #fff;
  font-size: 38px;
  top: 8px;
  left: -3px;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-hexagon:before,
#wdr-toolbar-wrapper #wdr-alert-view .wdr-hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-hexagon:before {
  left: 100%;
  border-left: 16.17px solid #999;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-hexagon:after {
  right: 100%;
  width: 0;
  border-right: 16.17px solid #999;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap {
  position: relative;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap .wdr-ui-icon {
  color: #fff;
  font-size: 38px;
  top: -2px;
  left: -1px;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap .wdr-triangle {
  position: relative;
  background-color: #999;
  text-align: left;
  display: block;
  margin-top: -7px;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:before,
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:after {
  content: '';
  position: absolute;
  background-color: inherit;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap .wdr-triangle,
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:before,
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:after {
  width: 35px;
  height: 35px;
  border-top-right-radius: 30%;
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap .wdr-triangle {
  -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  -ms-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:before {
  -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  -ms-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}
#wdr-toolbar-wrapper #wdr-alert-view .wdr-triangle-wrap .wdr-triangle:after {
  -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  -ms-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}
#wdr-toolbar-wrapper #wdr-alert-view.wdr-layout-mobile-small .wdr-content {
  margin-left: 0;
}
#wdr-toolbar-wrapper #wdr-alert-view.wdr-layout-mobile-small .wdr-alert-icon {
  display: none;
}
#wdr-toolbar-wrapper #wdr-alert-view span.wdr-ui-alert-label {
  color: #111;
  font-size: 14px;
}
#wdr-toolbar-wrapper #wdr-alert-view div.wdr-ui-alert-title-label {
  margin-right: 141px;
}
#wdr-toolbar-wrapper #wdr-alert-view #wdr-btn-apply.wdr-alert-button {
  margin-right: 109px;
}
#wdr-toolbar-wrapper #wdr-toolbar li#wdr-tab-fullscreen {
  width: 70px;
}
#wdr-toolbar-wrapper.wdr-mobile #wdr-toolbar li#wdr-tab-format-conditional {
  width: 80px;
}
/* Tabs */
#wdr-toolbar-wrapper #wdr-toolbar li {
  display: inline-block;
  background: none;
  vertical-align: top;
  padding: 0;
  position: relative;
  width: 57px;
}
#wdr-toolbar-wrapper #wdr-toolbar a {
  height: 78px;
  display: block;
  text-decoration: none;
  border: none;
}
#wdr-toolbar-wrapper #wdr-toolbar a > span {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 15px;
}
#wdr-toolbar-wrapper #wdr-toolbar li:first-child a {
  margin-left: 1px;
}
#wdr-toolbar-wrapper #wdr-toolbar li.wdr-divider {
  width: 0px;
  margin: 20px 15px 0px 15px;
  height: 40px;
  border-right: 1px solid #d5d5d5;
}
#wdr-toolbar-wrapper.wdr-mobile #wdr-toolbar li.wdr-divider {
  display: none;
}
#wdr-toolbar-wrapper #wdr-toolbar li.wdr-v-divider {
  height: 0px;
  width: 140px;
  border-bottom: 1px dotted #dcdcdc;
}
/* Tab menu */
#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown {
  position: absolute;
  display: none;
  top: 78px;
  z-index: 25;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown-content:after {
  content: '';
  border-width: 5px;
  border-color: transparent transparent #fff;
  border-style: outset outset solid;
  border-style: solid;
  top: -10px;
  left: 23px;
  width: 0;
  height: 0;
  position: absolute;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown-content:before {
  content: '';
  border-color: transparent transparent #d5d5d5;
  border-style: outset outset solid;
  border-width: 6px;
  position: absolute;
  top: -12px;
  left: 22px;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-align-rigth .wdr-dropdown-content:after {
  left: inherit;
  right: 20px;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-align-rigth .wdr-dropdown-content:before {
  left: inherit;
  right: 19px;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown-content {
  position: relative;
  padding: 0;
  list-style: none;
  background: #fff;
  border: 1px solid #d5d5d5;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown-content li {
  width: 155px;
  display: block;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown-content a {
  text-shadow: none;
  text-align: left;
  padding: 0;
  margin: 0px 1px 1px 1px;
  height: 37px;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown-content li:first-child a {
  margin-top: 1px;
}
/*#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown-content a.disabled {
        pointer-events: none;
        cursor: default;
        color: #AAA;
    }*/
#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown-content a:hover {
  background-color: #f1f1f1;
}
#wdr-toolbar-wrapper #wdr-toolbar .wdr-dropdown-content span {
  display: inline;
  position: absolute;
  left: 36px;
  right: 0;
  bottom: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
/* POPUP WINDOW */
#wdr-toolbar-wrapper div.wdr-popup {
  position: absolute;
  min-width: 270px;
  z-index: 100;
  font-family: Arial, sans-serif;
  max-width: 100%;
  background: #fff;
  border: 1px solid #d5d5d5;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 24px 30px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#wdr-toolbar-wrapper div.wdr-popup.wdr-popup-w700 {
  width: 700px;
}
#wdr-toolbar-wrapper div.wdr-popup.wdr-popup-w570 {
  width: 570px;
}
#wdr-toolbar-wrapper div.wdr-popup.wdr-popup-w500 {
  width: 500px;
}
#wdr-toolbar-wrapper div.wdr-popup.wdr-popup-w460 {
  width: 462px;
}
#wdr-toolbar-wrapper div.wdr-panel .wdr-toolbox {
  margin-top: 30px;
  text-align: center;
}
#wdr-toolbar-wrapper div.wdr-panel .wdr-title-bar .wdr-toolbox {
  margin-top: 0;
  text-align: right;
  float: right;
}
#wdr-toolbar-wrapper div.wdr-panel .wdr-toolbox .wdr-ui-btn {
  margin-right: 20px;
  height: 38px;
}
#wdr-toolbar-wrapper div.wdr-panel .wdr-toolbox .wdr-ui-btn:last-child {
  margin-right: 0;
}
#wdr-toolbar-wrapper div.wdr-panel .wdr-panel-content .wdr-title-bar {
  clear: both;
  margin-bottom: 24px;
}
#wdr-toolbar-wrapper div.wdr-panel .wdr-panel-content .wdr-title-bar .wdr-title-text {
  color: #111;
  font-size: 24px;
  text-align: center;
  text-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#wdr-toolbar-wrapper div.wdr-panel .wdr-panel-content .wdr-title-bar .wdr-title-text.wdr-ui-col {
  text-align: left;
}
#wdr-toolbar-wrapper #wdr-popUp-modal-overlay {
  z-index: 8;
}
#wdr-toolbar-wrapper #wdr-popUp-modal-overlay .wdr-modal-overlay {
  opacity: 0;
}
#wdr-toolbar-wrapper div.wdr-modal-overlay {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
#wdr-toolbar-wrapper #wdr-portrait-radio {
  margin-left: 14px;
}
#wdr-toolbar-wrapper #wdr-landscape-radio {
  margin-left: 14px;
}
#wdr-toolbar-wrapper div.wdr-panel.wdr-popup.wdr-layout-mobile {
  padding: 20px;
}
#wdr-toolbar-wrapper div.wdr-panel.wdr-popup.wdr-layout-mobile-small .wdr-panel-content .wdr-toolbox {
  float: none;
  text-align: left;
  width: 100%;
}
#wdr-toolbar-wrapper div.wdr-panel.wdr-popup.wdr-layout-mobile-small .wdr-panel-content .wdr-toolbox a.wdr-ui-btn {
  width: calc(50% - 10px);
  width: -webkit-calc(50% - 10px);
}
#wdr-toolbar-wrapper div.wdr-panel.wdr-popup.wdr-layout-mobile-small .wdr-panel-content .wdr-title-bar .wdr-title-text {
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  font-size: 18px;
}
/* Conditional formatting */
#wdr-toolbar-wrapper #wdr-popup-conditional {
  width: 610px;
  min-width: 400px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-panel-content {
  position: relative;
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-title-text {
  width: -webkit-calc(100% - 270px);
  width: calc(100% - 270px);
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-popup-content {
  overflow-x: hidden;
  overflow-y: auto;
  /*min-height: 149px;*/
  max-height: 298px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional a.wdr-ui-btn#wdr-add-btn {
  min-width: 40px;
  width: 40px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-condition-row {
  border-top: 1px solid #d5d5d5;
  padding: 30px 30px;
  margin-left: -30px;
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-condition-row .wdr-wrap-relative {
  position: relative;
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-condition-row .wdr-cr-delete {
  position: absolute;
  color: #999;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  cursor: pointer;
  font-size: 30px;
  right: 0;
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-condition-row wdr-cr-delete:hover {
  color: #555;
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-condition-row #wdr-values {
  width: 150px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-condition-row #wdr-conditions {
  width: 150px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-condition-row #wdr-font-family {
  width: 150px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-condition-row #wdr-font-size {
  width: 100px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional .wdr-condition-row #wdr-sample {
  width: 120px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional.wdr-layout-mobile #wdr-values {
  width: calc(100% - 100px);
  width: -webkit-calc(100% - 100px);
  margin-bottom: 10px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional.wdr-layout-mobile #wdr-conditions {
  width: calc(100% - 230px);
  width: -webkit-calc(100% - 230px);
  margin-left: 60px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional.wdr-layout-mobile #wdr-font-family {
  width: calc(100% - 100px);
  width: -webkit-calc(100% - 100px);
  margin-bottom: 10px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional.wdr-layout-mobile #wdr-font-size {
  width: calc(100% - 230px);
  width: -webkit-calc(100% - 230px);
  margin-left: 60px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional.wdr-layout-mobile #wdr-sample {
  width: 70px;
}
#wdr-toolbar-wrapper #wdr-popup-conditional.wdr-layout-mobile-small .wdr-title-text {
  width: 100%;
}
#wdr-toolbar-wrapper #wdr-popup-conditional.wdr-layout-mobile-small .wdr-panel-content .wdr-toolbox a.wdr-ui-btn:not(#wdr-add-btn) {
  width: calc(50% - 42px);
  width: -webkit-calc(50% - 42px);
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner {
  font-size: 0;
  margin-bottom: 10px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner:last-child {
  margin-bottom: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner .wdr-cr-lbl {
  display: inline-block;
  vertical-align: top;
  color: #999;
  font-size: 14px;
  margin-right: 10px;
  text-align: right;
  padding: 11px 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner .wdr-cr-lbl:last-child {
  margin-right: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner .wdr-select,
.wdr-cr-inner #wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-number-inp,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner .wdr-inp,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner .wdr-colorpick-wrap {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner .wdr-select:last-child,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner .wdr-number-inp:last-child,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner .wdr-inp:last-child,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cr-inner .wdr-colorpick-wrap:last-child {
  margin-right: 0;
}
#wdr-toolbar-wrapper .wdr-icon-act_trash:before {
  content: "\e908";
}
#wdr-toolbar-wrapper .wdr-toolbar-ui span#wdr-and-label {
  display: inline-block;
  text-align: center;
}
/* GENERAL */
#wdr-toolbar-wrapper div.wdr-popup input[type=text],
#wdr-toolbar-wrapper div.wdr-popup input[type=number],
#wdr-toolbar-wrapper div.wdr-popup input[type=password] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  background-color: #fff;
  height: 38px;
  padding: 0 9px;
  color: #111;
  font-size: 14px;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  text-align: left;
  font-family: Arial, sans-serif;
}
#wdr-toolbar-wrapper div.wdr-popup input:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  background-color: #fff;
}
#wdr-toolbar-wrapper div.wdr-popup input.wdr-half-input {
  padding-right: 0;
  border-right: none;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui select {
  border: 1px solid #d5d5d5;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  border-radius: 0px;
  margin: 0;
  outline: none;
  height: 38px;
  font-size: 14px;
  font-family: Arial, sans-serif;
  width: 100%;
  color: #111;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px 35px 4px 10px;
  cursor: pointer;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-select {
  position: relative;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-select:after {
  content: "\e902";
  font-family: 'webdatarocks-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  right: 9px;
  position: absolute;
  font-size: 21px;
  color: #999;
  /*margin-top: 1px;*/
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  pointer-events: none;
}
#wdr-toolbar-wrapper div.wdr-popup select::-ms-expand {
  display: none;
}
#wdr-toolbar-wrapper div.wdr-popup select:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  cursor: none;
  pointer-events: none;
}
#wdr-toolbar-wrapper div.wdr-popup select * {
  margin: 0 !important;
}
#wdr-toolbar-wrapper div.wdr-popup select option.placeholder {
  color: white;
  font-size: 0px;
  display: none;
}
#wdr-toolbar-wrapper div.wdr-clear {
  clear: both;
}
/* Hack for Firefox */
@-moz-document url-prefix() {
  #wdr-toolbar-wrapper select {
    text-indent: 0;
  }
}
/* COLOR PICKER */
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-btn {
  border: 1px solid #d5d5d5;
  height: 38px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 28px;
  cursor: pointer;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-btn span {
  font-size: 28px;
}
#wdr-toolbar-wrapper .wdr-icon-act_font:before {
  content: "\e90f";
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-popup {
  background: #fff;
  width: 355px;
  padding: 30px 30px;
  border: 1px solid #d5d5d5;
  position: absolute;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 0;
  visibility: hidden;
  left: 50%;
  margin-left: -178px;
  margin-top: -5px;
  z-index: 99;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-popup:before,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-popup:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-popup:before {
  width: 0px;
  height: 0px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-popup:after {
  width: 0px;
  height: 0px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-popup.wdr-arrow-up:before {
  top: -6px;
  border-left: 5.5px solid transparent;
  border-right: 5.5px solid transparent;
  border-bottom: 6px solid #d5d5d5;
  border-top: 0 solid transparent;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-popup.wdr-arrow-up:after {
  top: -5px;
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent;
  border-bottom: 5px solid #fff;
  border-top: 0 solid transparent;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-popup.wdr-arrow-down:before {
  bottom: -6px;
  border-left: 5.5px solid transparent;
  border-right: 5.5px solid transparent;
  border-top: 6px solid #d5d5d5;
  border-bottom: 0 solid transparent;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-colorpick-popup.wdr-arrow-down:after {
  bottom: -5px;
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent;
  border-top: 5px solid #fff;
  border-bottom: 0 solid transparent;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-popup-opened .wdr-colorpick-popup {
  visibility: visible;
  opacity: 1;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-color-targ-switch {
  border: 1px solid #d5d5d5;
  font-size: 0;
  margin-bottom: 20px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-color-targ-switch .wdr-cts-item {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  border-right: 1px solid #d5d5d5;
  line-height: 38px;
  font-weight: bold;
  color: #999;
  background: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-color-targ-switch .wdr-cts-item.wdr-current {
  color: #111;
  background: #f1f1f1;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-color-targ-switch .wdr-cts-item:hover {
  color: #111;
  background: #e3e3e3;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-color-targ-switch .wdr-cts-item:last-child {
  border-right: none;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-sett-row {
  margin-bottom: 20px;
  font-size: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-sett-row .wdr-cp-curr-color {
  border: 1px solid #d5d5d5;
  height: 38px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-sett-row .wdr-cp-2-colors {
  border: 1px solid #d5d5d5;
  font-size: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-sett-row .wdr-cp-2-colors .wdr-cp2c-item {
  height: 36px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-right: 1px solid #d5d5d5;
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-sett-row .wdr-cp-2-colors .wdr-cp2c-item:last-child {
  border-right: none;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-sett-row .wdr-inp,
.wdr-cp-sett-row .wdr-cp-curr-color,
.wdr-cp-sett-row .wdr-cp-2-colors {
  display: inline-block;
  vertical-align: top;
  margin-right: 13px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-sett-row .wdr-inp:last-child,
.wdr-cp-sett-row .wdr-cp-curr-color:last-child,
.wdr-cp-sett-row .wdr-cp-2-colors:last-child {
  margin-right: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-btns-row {
  margin-top: 20px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-btns-row .wdr-ui-btn {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
  margin-right: 10px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-btns-row .wdr-ui-btn:last-child {
  margin-right: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-currentmark {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #555;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-cp-currentmark:before {
  color: #fff;
  font-size: 16px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-9colors {
  font-size: 0;
  margin-bottom: 20px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-9colors .wdr-r9c-item {
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 36px;
  width: -webkit-calc(11.11% - 1px);
  width: calc(11.11% - 1px);
  margin-right: 1px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-9colors .wdr-r9c-item .wdr-r9c-arrow {
  position: absolute;
  top: 100%;
  border-style: solid;
  border-top-width: 10px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: none;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-9colors .wdr-r9c-item .wdr-cp-currentmark {
  margin-top: 2px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-9colors .wdr-r9c-item .wdr-r9c-arrow,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-9colors .wdr-r9c-item .wdr-cp-currentmark {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-9colors .wdr-r9c-item:nth-child(9n+9) {
  margin-right: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-9colors .wdr-r9c-item.wdr-current .wdr-r9c-arrow,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-9colors .wdr-r9c-item.wdr-current .wdr-cp-currentmark {
  opacity: 1;
  visibility: visible;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-4colors {
  font-size: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-4colors .wdr-r4c-item {
  display: inline-block;
  vertical-align: top;
  margin-right: 1px;
  margin-bottom: 1px;
  position: relative;
  cursor: pointer;
  height: 36px;
  width: -webkit-calc(25% - 0.75px);
  width: calc(25% - 0.75px);
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-4colors .wdr-r4c-item:nth-child(4n+4) {
  margin-right: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-4colors .wdr-r4c-item .wdr-cp-currentmark {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-row-4colors .wdr-r4c-item.wdr-current .wdr-cp-currentmark {
  opacity: 1;
  visibility: visible;
}
#wdr-toolbar-wrapper.wdr-mobile {
  overflow-x: auto;
}
/* new ui */
#wdr-toolbar-wrapper .wdr-radio-wrap label:before,
#wdr-toolbar-wrapper .wdr-radio-wrap label:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
#wdr-toolbar-wrapper .wdr-toolbar-ui div.wdr-title-2 {
  color: #999;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 25px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-radiobtn-list {
  margin-bottom: 52px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-radiobtn-list:last-child {
  margin-bottom: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-radiobtn-list li {
  margin-bottom: 12px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-radio-wrap label {
  display: inline-block;
  cursor: pointer;
  color: #111;
  font-size: 14px;
  padding: 4px 0;
  padding-left: 35px;
  position: relative;
  line-height: 1.2;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-radio-wrap label:before {
  width: 22px;
  height: 22px;
  border: 1px solid #d5d5d5;
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  left: 0;
  border-radius: 50%;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-radio-wrap label:after {
  background: #555;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  width: 14px;
  height: 14px;
  left: 5px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-radio-wrap label:hover:before {
  border-color: #999;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-radio-wrap input {
  display: none;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-radio-wrap input:checked + label {
  font-weight: bold;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-radio-wrap input:checked + label:after {
  opacity: 1;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-inp-group {
  margin-bottom: 30px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-inp-group:last-child {
  margin-bottom: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-inp-row {
  margin-bottom: 10px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-inp-row label {
  color: #999;
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-inp-row .wdr-select,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-inp-row .wdr-inp {
  width: 100%;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-inp-row:last-child {
  margin-bottom: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-ir-horizontal {
  font-size: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-ir-horizontal label {
  margin-bottom: 0;
  width: 140px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-ir-horizontal .wdr-select,
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-ir-horizontal .wdr-inp {
  width: -webkit-calc(100% - 160px);
  width: calc(100% - 160px);
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-uc {
  text-transform: uppercase;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-button-add {
  padding-right: 30px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-button-add .wdr-icon {
  color: #999;
  position: absolute;
  right: 0;
  width: 40px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  height: 100%;
  top: 0;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-button-add:hover .wdr-icon {
  color: #555;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-icon-act_add:before {
  content: "\e909";
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-icon-act_check:before {
  content: "\e901";
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width20.wdr-width20 {
  width: 20px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width40.wdr-width40 {
  width: 40px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width45.wdr-width45 {
  width: 45px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width50.wdr-width50 {
  width: 50px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width85.wdr-width85 {
  width: 85px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width120.wdr-width120 {
  width: 120px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width140.wdr-width140 {
  width: 140px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width180.wdr-width180 {
  width: 180px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width200.wdr-width200 {
  width: 200px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width210.wdr-width210 {
  width: 210px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width220.wdr-width220 {
  width: 220px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width230.wdr-width230 {
  width: 230px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width240.wdr-width240 {
  width: 240px;
}
#wdr-toolbar-wrapper .wdr-toolbar-ui .wdr-width250.wdr-width250 {
  width: 250px;
}
/* Connect to OLAP */
#wdr-toolbar-wrapper #wdr-popup-olap .wdr-ir-horizontal label {
  width: 110px;
}
#wdr-toolbar-wrapper #wdr-popup-olap .wdr-ir-horizontal .wdr-select {
  width: -webkit-calc(100% - 130px);
  width: calc(100% - 130px);
}
#wdr-toolbar-wrapper #wdr-popup-olap #wdr-inp-proxy-url {
  width: -webkit-calc(100% - 230px);
  width: calc(100% - 230px);
}
#wdr-toolbar-wrapper #wdr-popup-olap #wdr-btn-connect {
  width: 100px;
  height: 38px;
  padding-top: 10px;
}
#wdr-toolbar-wrapper #wdr-popup-olap.wdr-layout-mobile-small .wdr-ir-horizontal label {
  text-align: left;
  width: 100%;
}
#wdr-toolbar-wrapper #wdr-popup-olap.wdr-layout-mobile-small .wdr-ir-horizontal .wdr-select {
  width: 100%;
}
#wdr-toolbar-wrapper #wdr-popup-olap.wdr-layout-mobile-small #wdr-inp-proxy-url {
  width: -webkit-calc(100% - 100px);
  width: calc(100% - 100px);
}
/* Format cells */
#wdr-toolbar-wrapper #wdr-popup-format-cells {
  width: 460px;
}
#wdr-toolbar-wrapper #wdr-popup-format-cells.wdr-layout-mobile-small .wdr-ir-horizontal label {
  text-align: left;
  width: 100%;
}
#wdr-toolbar-wrapper #wdr-popup-format-cells.wdr-layout-mobile-small .wdr-ir-horizontal .wdr-select,
#wdr-toolbar-wrapper #wdr-popup-format-cells.wdr-layout-mobile-small .wdr-ir-horizontal .wdr-inp {
  width: 100%;
}
/* Options */
#wdr-toolbar-wrapper #wdr-popup-options {
  width: 570px;
  min-width: 320px;
}
/* layouts */
#wdr-toolbar-wrapper.wdr-layout-500 #wdr-toolbar #wdr-tab-fields,
#wdr-toolbar-wrapper.wdr-layout-500 #wdr-toolbar #wdr-tab-fullscreen {
  display: none;
}
#wdr-toolbar-wrapper.wdr-layout-360 #wdr-toolbar #wdr-tab-format {
  display: none;
}
#wdr-toolbar-wrapper.wdr-layout-300 #wdr-toolbar #wdr-tab-options {
  display: none;
}
.wdr-resize-triggers {
  visibility: hidden;
  opacity: 0;
}
.wdr-resize-triggers,
.wdr-resize-triggers > div,
.wdr-contract-trigger:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.wdr-resize-triggers > div {
  background: #eee;
  overflow: auto;
}
.wdr-contract-trigger:before {
  width: 200%;
  height: 200%;
}
#wdr-pivot-view .wdr-resizable-handle {
  position: absolute;
  cursor: w-resize;
}
#wdr-pivot-view .wdr-resizable-handle.wdr-left {
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}
#wdr-pivot-view .wdr-resizable-handle.wdr-right {
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}
/* ===== theme colors ===== */
/* ===== not used in webdatarocks-base.less directly; used in this file to define bg, border and other colors ===== */
/* ===== basic grey colors, common for all themes ===== */
/* ===== text colors ===== */
/* ===== background colors ===== */
/* ===== border colors ===== */
/* ===== grid ===== */
/* ===== ui ===== */
